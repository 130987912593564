import React from "react";

import { Switch } from "react-router-dom";

import Route from "./Route";

import { Redirect } from "react-router-dom";

import Integration from '../pages/Integration/Integration';
import {
    ProcoreSetUp,
    ProcoreProjects,
    ProcoreProjectImport,
    ProcoreLogs,
    ProcoreImportWorkers,
    ProcoreImportClassifications
} from '../pages/Integration/Components/Procore/index';

import {
    CMICProjectList,
    CMICImportWorkers,
    CMICProjectImport,
    CMICActivityLogs
} from '../pages/Integration/Components/CMIC/index';

import {
    AdpSetup,
    AdpSettings,
    AdpWorkers,
    AdpReport
} from '../pages/Integration/Components/Adp/index';

import PayrollIntegrations from '../pages/Integration/Components/PayrollIntegrationProcess/PayrollIntegrations'

export default function Routes(props) {

    return (
        <Switch>
            {/*MAIN ROUTES*/}
            <Route path="/home/:sessionId?" component={Integration} globalState={props} />

            {/*PROCORE ROUTES*/}
            <Route path="/procore-set-up" exact component={ProcoreSetUp} globalState={props} />

            <Route path="/embedded/:sessionId?" exact component={Integration} globalState={props} />
            <Route path="/procore-projects" exact component={ProcoreProjects} globalState={props} />
            <Route path="/sb-projects" exact component={ProcoreProjectImport} globalState={props} />
            <Route path="/procore-import-workers" exact component={ProcoreImportWorkers} globalState={props} />
            <Route path="/procore-import-classifications" exact component={ProcoreImportClassifications} globalState={props} />
            <Route path="/procore-logs" exact component={ProcoreLogs} globalState={props} />

            {/* CMIC Routes */}
            <Route path="/cmic-projects" exact component={CMICProjectList} globalState={props} />
            <Route path="/cmic-project-import" exact component={CMICProjectImport} globalState={props} />
            <Route path="/cmic-import-workers" exact component={CMICImportWorkers} globalState={props} />
            <Route path="/cmic-activity-logs" exact component={CMICActivityLogs} globalState={props} />

            {/*ADP ROUTES*/}
            <Route path="/adp/callback" component={AdpSetup} globalState={props} />
            <Route path="/adp-settings" exact component={AdpSettings} globalState={props} />
            <Route path="/adp-workers" exact component={AdpWorkers} globalState={props} />
            <Route path="/adp-hours" exact component={AdpReport} globalState={props} />

            {/* PayrollIntegrations Mudole */}
            <Route path="/payroll-config/:key" component={PayrollIntegrations} globalState={props} />

            <Route path="*">
                <Redirect to="/home" />
            </Route>
        </Switch>
    );
}