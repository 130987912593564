import { get } from "lodash";
import axios from 'axios';

const baseUrl = get(process.env, 'REACT_APP_BASE_URL', 'https://integrations.connectedbarrelapi.com');


const setHeaders = () => {
    return {
        'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
        'Accept': 'application/json',
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': "*"
    };
};

// Adp Services
export const setupAdpService = () => {
    return axios.get(`${baseUrl}/adp/callback`, { headers: setHeaders() })
};


export const workersSyncServices = () => {

    return axios.get(`${baseUrl}/sync-workers`, { headers: setHeaders() })
};


export const getAdpWorkersService = (pagination) => {

    return axios.post(`${baseUrl}/adp-workers`, { pagination }, { headers: setHeaders() })
};

export const mappingReadyWorkers = (payload) => {
    // console.log(payload)
    return axios.post(`${baseUrl}/ready-workers`, payload, { headers: setHeaders() })
};

// export const deleteRevoke = 


export const adpSettingsServices = () => {
    return axios.get(`${baseUrl}/settings-config`, { headers: setHeaders() })
};
