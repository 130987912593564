import React, { useEffect, useState } from 'react';
import {
    getAdpWorkersService,
    mappingReadyWorkers
} from '../../../../services/adpServices';
import { get, isNull } from "lodash";
import {
    AdpWorkerTableComponent,
    AdpWorkersTableBodyComponent,
    WorkerSyncSummary
} from './index';
import { makeStyles } from "@material-ui/core/styles";

import Pagination from '@material-ui/lab/Pagination';
import { getUserInfo } from '../../../../services/appService';

const useStyles = makeStyles(theme => ({
    pagination: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'flex-end'
    },
}));

const W3CWebSocket = require('websocket').w3cwebsocket;


export default function AdpWorkers(props) {

    const adpWorkerClasses = useStyles();

    const [state, setState] = useState({
        workers: [],
        user: getUserInfo(),
        readyWorkerSynced: false,
        headers: [],
        counter: {},
        pagination: {
            total: 0,
            page: 1,
        }
    });

    const getWorkers = async () => {
        const res = await getAdpWorkersService(state.pagination);
        toggleSpinner(false)
        setState(prevState => ({
            ...prevState,
            workers: get(res, 'data.workers'),
            headers: get(res, 'data.headers'),
            counter: get(res, 'data.counter'),
            pagination: {
                ...prevState.pagination,
                total: Math.ceil(get(res, 'data.counter.total') / 25)
            }
        }))
    };

    useEffect(() => {

        toggleSpinner(true)

        getWorkers();
        openWebSocketConnection();

    }, [state.pagination.page]);

    const openWebSocketConnection = () => {
        let client = new W3CWebSocket(`${get(process.env, 'REACT_APP_WSS_CONNECTION')}?userId=${get(state.user, 'user_id')}&companyId=${get(state.user, 'company_id')}`);

        client.onopen = (e) => {
            console.log("OPEN ", e);
        };

        client.onclose = (e) => {
            console.log("ONCLOSE ", e);
            client = null;

            this.openWebSocketConnection();
        };

        client.onmessage = (e) => {

            const res = JSON.parse(get(e, "data"));

            console.log("RES SOCKETS ", res)

            switch (get(res, 'section.key')) {
                case "readyWorker":
                    props.globalState.onSpinner(false);

                    setState(prevState => ({
                        ...prevState,
                        readyWorkerSynced: false
                    }))
                    getWorkers();
                    break;
                case "readyWorkers":
                    setState(prevState => ({
                        ...prevState,
                        readyWorkerSynced: false
                    }))
                    getWorkers();
                    break;
                default:
                    break;
            }

        };
    };


    const toggleSpinner = (spinnerFlag = true) => {
        props.globalState.onSpinner(spinnerFlag);
    };


    const onReadyWorkers = async (workerId = null) => {
        setState(prevState => ({
            ...prevState,
            readyWorkerSynced: true
        }));

        mappingReadyWorkers({ workerId });


    }

    const onChangePage = (e, page) => {

        setState(prevState => ({
            ...prevState,
            pagination: {
                ...prevState.pagination,
                page
            }
        }));
    }

    return (

        <div>

            <WorkerSyncSummary readyWorkerSynced={state.readyWorkerSynced} onReadyWorkers={onReadyWorkers} counter={state.counter} />

            <AdpWorkerTableComponent headers={state.headers}>
                <AdpWorkersTableBodyComponent readyWorkerSynced={state.readyWorkerSynced} onReadyWorkers={onReadyWorkers} workers={state.workers} />
            </AdpWorkerTableComponent>

            <Pagination
                classes={{ root: adpWorkerClasses.pagination }}
                component="div"
                count={state.pagination.total}
                page={state.pagination.page}
                onChange={onChangePage}

            />
        </div>

    )
};