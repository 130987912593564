import React, { useState, useEffect, Fragment } from 'react'
import * as _ from 'lodash';
import { getPayrollConfig, getPayrollData, pushingHours } from '../../../../services/payrollIntegrations';
import { getUserInfo, notificationCenter } from '../../../../services/appService';
import { Widget, ArrowPagination } from '../../../../components';
import FiltersConfigComponent from './FiltersConfigComponent';
import { Table } from "antd";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';

const W3CWebSocket = require('websocket').w3cwebsocket;

export default function PayrollIntegrations(props) {

  const integrationKey = _.get(props, 'match.params.key', 'adp')

  const [state, setState] = useState({
    headers: [],
    filters: [],
    columns: [],
    page: 1,
    readyToPush: false,
    user: getUserInfo(),
    batch: {
      status: false,
      name: `BatchName${new Date().getTime()}`
    }
  })

  const onToggleSpinner = (flag = false) => props.globalState.onSpinner(flag);

  useEffect(() => {
    onToggleSpinner(true);
    openWebSocketConnection();
    getPayrollConfig(integrationKey).then(res => {
      onToggleSpinner();
      setState(prevState => ({
        ...prevState,
        headers: _.get(res, 'headers'),
        filters: _.get(res, 'filters')
      }))
    }).catch(er => {
      onToggleSpinner();
      props.globalState.onError(er)
    })
  }, []);

  const openWebSocketConnection = () => {
    let client = new W3CWebSocket(`${_.get(process.env, 'REACT_APP_WSS_CONNECTION')}?userId=${_.get(state.user, 'user_id')}&companyId=${_.get(state.user, 'company_id')}`);

    client.onopen = (e) => {
      console.log("OPEN ", e);
    };

    client.onclose = (e) => {
      console.log("ONCLOSE ", e);
      client = null;

      this.openWebSocketConnection();
    };

    client.onmessage = (e) => {

      console.log("ON MESSAGE ", e)

      const res = JSON.parse(_.get(e, "data"));

      console.log("RES SOCKETS ", res)

      switch (_.get(res, 'section.key')) {
        case "batchErrors":
          _.each(_.get(res, 'section.value'), item => {
            notificationCenter(_.get(item, 'type'), _.get(item, 'value'));
          })
          break;
        case "batchSuccess":
          notificationCenter('success', _.get(res, 'section.value'));
          break;
        default:
          break;
      }

    };
  };

  const onGettingData = (payload, page = 1) => {

    _.set(payload, 'page', page);
    _.set(payload, 'integrationKey', integrationKey);

    onToggleSpinner(true);

    getPayrollData(payload, integrationKey).then(res => {
      setState(prevState => ({
        ...prevState,
        columns: _.get(res, 'columns'),
        readyToPush: _.size(_.get(res, 'columns')) > 0
      }));
      onToggleSpinner(false);

    }).catch(err => {
      onToggleSpinner(false);
      props.globalState.onError(err)
    })
  }

  const onPushingHours = () => {
    setState(prevState => ({
      ...prevState,
      batch: {
        ...prevState.batch,
        status: true
      }
    }))

  }

  const onCloseBatchInfo = () => {
    setState(prevState => ({
      ...prevState,
      batch: {
        ...prevState.batch,
        status: false
      }
    }))
  }

  const onHandleBatchName = val => {
    setState(prevState => ({
      ...prevState,
      batch: {
        ...prevState.batch,
        name: val
      }
    }))
  }

  const onPushBatch = () => {

    setState(prevState => ({
      ...prevState,
      batch: {
        ...prevState.batch,
        status: false
      }
    }))

    onToggleSpinner(true);
    pushingHours({ filters: state.filters, batchId: state.batch.name }, integrationKey).then(res => {
      onToggleSpinner();
      setState(prevState => ({
        ...prevState,
        hasData: false
      }));
      notificationCenter('warning', "You'll be notified when the process finish")

    }).catch(err => {
      onToggleSpinner();
      props.globalState.onError(err)
    })
    console.log(state.batch)
  }

  const onPaginationChange = (key, page) => {

    if (key === 'right') {
      page += 1;
    } else if (key === 'left' && state.page > 2) {
      page -= 1;
    }

    setState(prevState => ({
      ...prevState,
      page
    }))

    onGettingData({ filters: state.filters }, page)
  }

  return (
    <Fragment>
      <Widget title=''>
        <FiltersConfigComponent
          onGettingData={onGettingData}
          onPushingHours={onPushingHours}
          hasData={state.readyToPush}
          filters={state.filters}
          integrationKey={integrationKey} />
      </Widget>

      <div className='payroll-data'>
        <Table dataSource={state.columns} pagination={false} columns={state.headers} />

        {state.columns && _.size(state.columns) > 10 ?
          <ArrowPagination
            page={state.page}
            onPaginationChange={onPaginationChange}
          /> : null
        }
      </div>
      <Dialog open={_.get(state, 'batch.status', false)} onClose={onCloseBatchInfo} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Select Batch Id</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Batch Id"
            type="text"
            onChange={ev => onHandleBatchName(ev.target.value)}
            value={state.batch.name}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseBatchInfo} color="primary">
            Cancel
          </Button>
          <Button onClick={onPushBatch} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>

    </Fragment>
  )
};