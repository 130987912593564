import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

export default function CMICTableComponent(props) {
  const { headers, children } = props;

  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          {headers.map((header, idx) => (
            <TableCell align={header.align} key={idx}>{header.title}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {children}
      </TableBody>
    </Table>
  );
};