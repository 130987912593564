import React from 'react';
import Info from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { Fab, IconButton, makeStyles } from '@material-ui/core';
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative'
  },
  icon: {
    position: 'absolute',
    // right: "40px",
    // top: "0px",
    color: "#a9a9a9",
    fontSize: '15px'
  },
}));

export default function TooltipInfo(props) {
  const classes = useStyles();

  const { tooltipInfo, tooltipPosition, labelText, labelStyles } = props;

  return (
    <div className={classes.container}>
      <b style={labelStyles}>{labelText}</b>
      <Tooltip title={tooltipInfo} placement={tooltipPosition}>
        <Info className={classes.icon} />

      </Tooltip>
    </div>
  );
}

TooltipInfo.propTypes = {
  labelText: PropTypes.string.isRequired,
  tooltipInfo: PropTypes.string,
  tooltipPosition: PropTypes.string,
  labelStyles: PropTypes.object
};

TooltipInfo.defaultProps = {
  labelText: "Label Text",
  tooltipInfo: "Information loading...",
  tooltipPosition: 'top-start'
};
