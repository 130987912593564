import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        margin: '-30px -30px 0px'
    }
}));


export default function CardBox({ title, logoSrc, children, className, contentClassName, containerStyle }) {
    const classes = useStyles();


    return (
        <div className={`jr-card text-center card-presentational ${className}`} style={containerStyle}>
            <div className={`jr-card-header-color ${classes.cardHeader}`} style={{ borderBottom: '1px solid' }}>
                <div className='integration-logo'>
                    <img width='70px' height='auto' className="mb-3" src={logoSrc} alt="Integration Logo" />
                </div>

                <div className="jr-card-hd-content">
                    <b>{title}</b>
                </div>
            </div>
            <div className={`jr-card-body pt-2 ${contentClassName}`}>
                {children}
            </div>
        </div>
    )
}

CardBox.propTypes = {
    title: PropTypes.string.isRequired,
    logoSrc: PropTypes.string,
}
