import React from 'react';

const InfinitePagination = ({ onPaginationChange, page, pageSize, dataSize }) => {


  return (
    <div className='adp-pagination d-flex justify-content-end mr-4 mt-4'>
      <div onClick={() => page > 1 && onPaginationChange('left', page - 1)} className="left">
        <i style={{
          'fontSize': '30px',
          'cursor': `${page > 1 ? 'pointer' : 'no-drop'}`,
          'color': `${page > 1 ? 'black' : '#D3D3D3'}`
        }} className="zmdi zmdi-chevron-left mr-3" />
      </div>
      <div className="right" onClick={() => dataSize >= pageSize && onPaginationChange('right', page + 1)}>
        <i style={{
          'fontSize': '30px',
          'cursor': `${dataSize >= pageSize ? 'pointer' : 'no-drop'}`,
          'color': `${dataSize >= pageSize ? 'black' : '#D3D3D3'}`
        }} className="zmdi zmdi-chevron-right" />
      </div>
    </div>

  );
};

export default InfinitePagination;