import axios from 'axios';
import { get } from 'lodash';

const baseUrl = get(process.env, 'REACT_APP_BASE_URL', 'https://integrations.connectedbarrelapi.com');

const setHeaders = () => {
    return {
        'Authorization': `Bearer ${localStorage.getItem("userToken")}`,
        'Accept': 'application/json',
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': "*"
    };
};


export const getIntegrations = () => {

    return axios.get(`${baseUrl}/integrations`, { headers: setHeaders() });
};

export const getTimezones = () => {
    return axios.get(`${baseUrl}/integration-timezones`, { headers: setHeaders() });
}
export const getProjectFilter = () => {
    return axios.get(`${baseUrl}/projects-filter`, { headers: setHeaders() });
}

//Procore Service
export const setActiveStatusCompanyFromProcore = (vendors, projId) => {

    console.log(vendors);

    return new Promise((resolve, reject) => {

        axios.post(`${baseUrl}/set-active-vendor/${projId}`, { vendors }, { headers: setHeaders() }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });

    });
};

export const changingStatus = (integrationStatus, integrationKey) => {
    return axios.get(`${baseUrl}/procore-change-status/${integrationKey}/${integrationStatus}`, { headers: setHeaders() })
}

export const switchingUser = () => {
    return axios.get(`${baseUrl}/procore-switch-user`, { headers: setHeaders() })
}

const getIntegrationConfig = (integrationKey) => {

    return new Promise((resolve, reject) => {
        axios.get(`${baseUrl}/integrations/config/${integrationKey}`, { headers: setHeaders() }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });
    });
};

export const cmicSetUpService = async (credentials) => {

    return axios.post(`${baseUrl}/cmic-setup`, { credentials }, { headers: setHeaders() });
}

export const activateIntegration = async (integration) => {
    const integrationKey = get(integration, 'key');
    const config = get(await getIntegrationConfig(integrationKey), 'data');
    let url = '';

    console.log("config , ", config)

    switch (integrationKey) {
        case 'procore':
            url = `${get(process.env, 'REACT_APP_PROCORE_LOGIN_URL')}/oauth/authorize?response_type=code&client_id=${get(config, 'client_id')}&redirect_uri=${get(config, 'redirect_url')}`;
            break;
        case 'adp':
            url = get(config, 'loginUrl');
            break;
        default:
            break;
    }

    window.location.href = url;
};

export const setupProcoreService = code => {

    return new Promise((resolve, reject) => {

        axios.get(`${baseUrl}/procore-setup/${code}`, { headers: setHeaders() }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });

    });
};

export const company_integrations_update = (data) => {
    return new Promise((resolve, reject) => {
        axios.patch(`${baseUrl}/company-integration`, data, { headers: setHeaders() }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });

    });
};

export const procoreLogs = (payload) => {
    return axios.post(`${baseUrl}/procore-logs`, payload, { headers: setHeaders() });
};

export const procoreProjects = (page) => {
    return new Promise((resolve, reject) => {
        axios.post(`${baseUrl}/procore-projects`, { page }, { headers: setHeaders() }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });
    });
};


export const updateProcoreProjects = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${baseUrl}/update-procore-projects`, { headers: setHeaders() }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });
    });
};

export const sbProjects = (projAddress) => {
    return new Promise((resolve, reject) => {

        axios.post(`${baseUrl}/sb-projects`, { projAddress }, { headers: setHeaders() }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });

    });
};

export const syncAllProcoreUsers = () => {
    return new Promise((resolve, reject) => {

        axios.get(`${baseUrl}/procore-sync-users`, { headers: setHeaders() }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });

    });
};


export const syncAllProcoreClassifications = () => {
    return axios.get(`${baseUrl}/procore-sync-classifications`, { headers: setHeaders() })
};

export const procoreProjectSync = proj_procore_id => {

    return new Promise((resolve, reject) => {

        axios.get(`${baseUrl}/procore-sync/${proj_procore_id}`, { headers: setHeaders() }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });
    });
};

export const procoreProjectSummary = (proj_procore_id, procCompanyId) => {

    return new Promise((resolve, reject) => {

        axios.get(`${baseUrl}/procore-project-summary/${proj_procore_id}/${procCompanyId}`, { headers: setHeaders() }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });

    });

};

export const setProcProjectSettings = (payload) => {

    return new Promise((resolve, reject) => {
        axios.post(`${baseUrl}/procore-project-settings`, payload, { headers: setHeaders() }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        });

    });
};

export const searchProcoreProject = (search) => {
    return axios.get(`${baseUrl}/procore-project-search/${search}`, { headers: setHeaders() })
}
export const importProcoreProject = data => {
    return axios.post(`${baseUrl}/procore-project-import`, { data }, { headers: setHeaders() })
};

export const getUserProfile = () => {
    return axios.get(`${baseUrl}/user-profile`, { headers: setHeaders() })
}
