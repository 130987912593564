import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { get, isEmpty, size } from 'lodash';

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    maxWidth: 300
  },
  // selectEmpty: {
  //   marginTop: theme.spacing(2)
  // },
  // checkBox: {
  //   paddingTop: '20px'
  // }
}));

const CompanyCodesFilterComponent = (props) => {

  const { filter, onFilterChange } = props;

  const classes = useStyles();

  const [code, setCode] = useState({});

  const onLocalChange = (value) => {
    setCode(value)
    onFilterChange('companyCodes', value);
  };


  return (
    <div className='d-flex justify-content-center align-items-center'>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Company Code</InputLabel>
        <Select
          id="demo-mutiple-name"
          labelId="demo-simple-select-label"
          value={code}
          onChange={e => onLocalChange(e.target.value)}>
          {get(filter, 'value', []).map((item, idx) => (
            <MenuItem key={idx} value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
};

export default CompanyCodesFilterComponent;