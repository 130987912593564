import React from "react";
import {DialogConfirmation, Widget, StatusField} from "../../../../components";
import {get} from "lodash";
import {ProcoreTableComponent} from './index';
import {
    notValid
} from '../../../../services/appService';
import {TableCell, TableRow} from "@material-ui/core";
import PropTypes from "prop-types";

const ProcoreProjectSummary = (props) => {

    const {projectSummary, status, onSummaryClose} = props;

    const procProjectInfo = (icon, title, text) => {
        return (
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                <div className="media align-items-center flex-nowrap jr-pro-contact-list">
                    <div className="mr-3">
                        <i className={`zmdi ${icon} jr-fs-xxl text-orange`}/>
                    </div>
                    <div className="media-body">
                        <span className="mb-0 text-grey jr-fs-sm">{title}</span>
                        <p className="mb-0">{text}</p>
                    </div>
                </div>
            </div>
        );
    };

    const vendorTableHeaders = [
        {
            title: 'Name',
            align: 'left'
        },
        {
            title: 'Address',
            align: 'left'
        },
        {
            title: 'City',
            align: 'left'
        },
        {
            title: 'State',
            align: 'left'
        },
        {
            title: 'Company',
            align: 'left'
        },
        {
            title: 'EIN Number',
            align: 'left'
        }
    ];


    const vendorTable = () => {

        const onTableCell = (vendor, key) => {
            let text = key === 'ein' ? "Required Field" : 'Missing Field';
            let color = key === 'ein' ? 'red' : 'orange';
            return (
                <TableCell>
                    {!notValid(get(vendor, key)) ? get(vendor, key) :
                        <StatusField text={text} color={color}/>}
                </TableCell>
            );
        };

        return (
            <ProcoreTableComponent headers={vendorTableHeaders}>
                {get(projectSummary, 'vendors', []).map(vendor => (
                    <TableRow key={vendor.id}>
                        <TableCell component="th" scope="row">
                            {get(vendor, 'name', '-')}
                        </TableCell>
                        {onTableCell(vendor, 'address')}
                        {onTableCell(vendor, 'city')}
                        {onTableCell(vendor, 'state_code')}
                        {onTableCell(vendor, 'company')}
                        {onTableCell(vendor, 'ein')}
                    </TableRow>
                ))}
            </ProcoreTableComponent>
        );
    };

    const projectSummaryTemplate = () => {

        return (
            <div>
                <Widget title='Procore Project INFO.' styleName="jr-card-profile-sm">
                    <div className='row'>
                        {procProjectInfo('zmdi-city-alt', 'Name', get(projectSummary, 'name'))}
                        {procProjectInfo('zmdi-gps-dot', 'State', `${get(projectSummary, 'state_code', '-')}`)}
                        {procProjectInfo('zmdi-alert-triangle', 'Project #', get(projectSummary, 'project_number', '-'))}
                        {procProjectInfo('zmdi-city-alt', 'Company', get(projectSummary, 'company', '-'))}
                    </div>
                </Widget>


                {get(projectSummary, 'sbProject') &&
                <Widget title='SmartBarrel Project INFO.' styleName="jr-card-profile-sm">
                    <div className='row'>
                        {procProjectInfo('zmdi-city-alt', 'Name', get(projectSummary, 'sbProject.name'))}
                        {procProjectInfo('zmdi-gps-dot', 'State', `${get(projectSummary, 'sbProject.state', '-')}`)}
                        {procProjectInfo('zmdi-alert-triangle', 'Project #', get(projectSummary, 'sbProject.project_number', '-'))}
                        {procProjectInfo('zmdi-city-alt', 'Company', get(projectSummary, 'sbProject.company', '-'))}
                    </div>
                </Widget>
                }

                {get(projectSummary, 'sbProject') &&
                <Widget title='Summary INFO.' styleName="jr-card-profile-sm">

                    <div className='summary-main-content d-flex'>
                        <div className='workerSync mr-4'>
                            <img
                                src="https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/integrations/WorkerSyncIcon.svg"
                                alt=""
                                width='50px'
                            />
                            <b className='ml-2'>Workers Synced: </b> {get(projectSummary, 'workersSync')}
                        </div>

                        <div className='costCodeSync'>
                            <img
                                src="https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/integrations/CostCodeIcon.svg"
                                alt=""
                                width='50px'
                            />
                            <b className='ml-2'>Workers Synced: </b> {get(projectSummary, 'costCodeSync')}
                        </div>
                    </div>

                </Widget>}

                <h2>Vendors</h2>

                <div className='row'>
                    {vendorTable()}
                </div>

            </div>
        );

    };
    return (
        <DialogConfirmation open={status}
                            onHandleClose={onSummaryClose}
                            disableBackdropClick={false}
                            title='Project Summary'
                            agreeText='Done'
                            maxWidth='lg'
                            showActions={false}
                            doneAction={true}
                            dialogContent={projectSummaryTemplate()}/>
    );

};

ProcoreProjectSummary.propTypes = {
    projectSummary: PropTypes.object,
    status: PropTypes.bool,
    onSummaryClose: PropTypes.func
};


export default ProcoreProjectSummary;