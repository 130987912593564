import React, { Fragment, useState } from 'react';
import { Widget } from "../../../../components";
import { get } from "lodash";
import { Button, CircularProgress } from '@material-ui/core';
import { syncReadyWorkers } from '../../../../services/adpServices'
import { notificationCenter } from '../../../../services/appService'

function WorkerSyncSummary(props) {

    const { counter, onReadyWorkers, readyWorkerSynced } = props;



    const onSyncReadyWorkers = () => {
        notificationCenter('warning', "You would be notifier when the process finish");

        onReadyWorkers();
    }

    return (
        <Fragment>
            <Widget title='Summary' styleName="jr-card-profile-sm">
                <div className='row'>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                        <div className="media align-items-center flex-nowrap jr-pro-contact-list">
                            <div className="mr-3">
                                <i className={`zmdi zmdi-city-alt jr-fs-xxl text-orange`} />
                            </div>
                            <div className="media-body">
                                <span className="mb-0 text-grey jr-fs-sm">Total Workers</span>
                                <p className="mb-0">{get(counter, 'total', 0)}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                        <div className="media align-items-center flex-nowrap jr-pro-contact-list">
                            <div className="mr-3">
                                <i className={`zmdi zmdi-city-alt jr-fs-xxl text-orange`} />
                            </div>
                            <div className="media-body">
                                <span className="mb-0 text-grey jr-fs-sm">Ready to sync</span>
                                <p className="mb-0">{get(counter, 'readyCounter', 0)}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                        <div className="media align-items-center flex-nowrap jr-pro-contact-list">
                            <div className="mr-3">
                                <i className={`zmdi zmdi-city-alt jr-fs-xxl text-orange`} />
                            </div>
                            <div className="media-body">
                                <span className="mb-0 text-grey jr-fs-sm">Missing information</span>
                                <p className="mb-0">{get(counter, 'missingCounter', 0)}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                        <div className="media align-items-center flex-nowrap jr-pro-contact-list">
                            <div className="mr-3">
                                <i className={`zmdi zmdi-city-alt jr-fs-xxl text-orange`} />
                            </div>
                            <div className="media-body">
                                <span className="mb-0 text-grey jr-fs-sm">Already Sync</span>
                                <p className="mb-0">{get(counter, 'synced', 0)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Widget>

            <Widget title='Actions' styleName="jr-card-profile-sm">
                <div className='row'>
                    <Button onClick={() => onSyncReadyWorkers()} variant="contained" disabled={readyWorkerSynced} className={`jr-btn text-white ${readyWorkerSynced ? 'bg-gray' : 'bg-success'}`}>{readyWorkerSynced ? <CircularProgress className='mr-2' size={10} color="primary" /> : null} Sync All Workers</Button>
                </div>

            </Widget>


        </Fragment>
    );
}

export default WorkerSyncSummary;