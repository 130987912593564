import axios from 'axios';
import { get } from 'lodash';

const baseUrl = get(process.env, 'REACT_APP_BASE_URL', 'https://integrations.connectedbarrelapi.com');
const projectDashboardUrl = get(process.env, 'REACT_APP_PROJECT_DASHBOARD_API', 'https://integrations.connectedbarrelapi.com');


const setHeaders = () => {
  return {
    'Authorization': `Bearer ${localStorage.getItem("userToken")}`,
    'Accept': 'application/json',
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': "*"
  };
};


export const cmicProjects = (page) => {
  return axios.post(`${baseUrl}/cmic-projects`, { page }, { headers: setHeaders() })
};

export const updateCMICProjects = () => {
  return axios.get(`${baseUrl}/cmic-update-projects`, { headers: setHeaders() })
}


export const getCMICSubJobs = (projectNumber) => {
  return axios.get(`${baseUrl}/get-cmic-subjobs/${projectNumber}`, { headers: setHeaders() })
}

export const saveCMICPerDiem = (payload, projectId) => {
  return axios.post(`${baseUrl}/cmic-project-perdiem/${projectId}`, { ...payload }, { headers: setHeaders() })
}


export const syncCMICSubJobs = (subs, projectNumber) => {
  return axios.post(`${baseUrl}/sync-cmic-subjobs/${projectNumber}`, { subs }, { headers: setHeaders() })
}


export const gettingActivityLogs = (data) => {
  return axios.post(`${baseUrl}/cmic-activity-logs`, data, { headers: setHeaders() })
};

export const addCmicSettings = (data) => {
  return axios.post(`${baseUrl}/cmic-settings`, data, { headers: setHeaders() })
};

export const getCmicSettings = () => {
  return axios.get(`${baseUrl}/get-cmic-settings`, { headers: setHeaders() })
};


export const cmicSBProjects = (projAddress) => {
  return axios.post(`${baseUrl}/cmic-sb-projects`, { projAddress }, { headers: setHeaders() })
};

export const importCMICProjects = (data) => {
  return axios.post(`${baseUrl}/cmic-import-project`, { data }, { headers: setHeaders() })
};

export const searchCMICProjects = (search) => {
  return axios.get(`${baseUrl}/cmic-search-project/${search}`, { headers: setHeaders() })
};

export const syncAllWorkers = () => {
  return axios.get(`${baseUrl}/cmic-workers`, { headers: setHeaders() })
};

export const syncAllCostcodes = () => {
  return axios.get(`${baseUrl}/cmic-workers`, { headers: setHeaders() })
};

// export const getRevokeCMICWorkerURL = () => {
//   return axios.get(`${projectDashboardUrl}/revoke-cmic-workers`, { headers: setHeaders() })
// }

