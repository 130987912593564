import React, { useState, useContext, useEffect } from "react";

import { get, filter, isEmpty, remove, size, set, isNull } from 'lodash';

import {
    procoreProjectsHeaders
} from '../../../../constants/helpers'

import {
    procoreProjects,
    procoreProjectSync,
    procoreProjectSummary,
    setProcProjectSettings,
    setActiveStatusCompanyFromProcore,
    updateProcoreProjects,
    searchProcoreProject
} from '../../../../services/integrationService';

import {
    notificationCenter,
    notValid,
    getUserInfo
} from '../../../../services/appService';

import {
    DialogConfirmation,
    Widget,
    SearchBox
} from "../../../../components/index";

import {
    ProcoreProjectVendors,
    ProcoreTableComponent,
    ProcoreProjectSummary,
    ProcoreProjectsBody
} from './index'

import '../../../../static/styles/components/_procoreProjects.scss';
import { errorService } from "../../../../services/errorService";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import SearchRounded from '@material-ui/icons/SearchRounded';
import Warning from '@material-ui/icons/Warning';
import { SettingsContext } from "../../../../contexts/SettingsContext";
import { AuthContext } from "../../../../contexts/AuthContext";

const W3CWebSocket = require('websocket').w3cwebsocket;

export default function ProcoreProjects(props) {

    const settingsContext = useContext(SettingsContext);
    const authContext = useContext(AuthContext);

    const [state, setState] = useState({
        projects: [],
        user: JSON.parse(localStorage.getItem('userInfo')),
        page: 1,
        totalPages: 0,
        searchValue: null,
        oldProjects: [],
        counter: null,
        vendors: {
            warningVendors: [],
            successVendors: []
        },
        procoreLogo: 'https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/integrations-logos/procore-logo.png',
        vendorsStatus: false,
        vendorsDialogAction: false,
        dailyLogSettingsDialog: false,
        projectSetting: {
            procId: null,
            weather_sync: null,
            timeCards_sync: null,
            worker_sync: null,
            costCode_sync: null,
            import_costCode_sync: null
        },
        onSetProjectSettings: false,
        vendorsProcoreDisabled: [],
        directoryAdminPermissions: true,
        lastProjectImported: null,
        currentProject: null,
        procoreProjTable: procoreProjectsHeaders,
        projectSummary: {
            status: false,
            data: null
        }
    });

    const [syncAllProjects, setSyncAllProjects] = useState(false);

    const toggleSpinner = (spinnerFlag = true, text = 'Loading...') => {
        settingsContext.onChangeLoader(spinnerFlag, text);
    };

    const onError = e => {
        toggleSpinner(false);
        settingsContext.onRequestErrorMessage(e.response);
    };


    const openWebSocketConnection = () => {
        let client = new W3CWebSocket(`${get(process.env, 'REACT_APP_WSS_CONNECTION')}?userId=${get(state.user, 'user.user_id')}&companyId=${get(state.user, 'user.company_id')}`);

        client.onopen = (e) => {
            console.log("OPEN ", e);
        };

        client.onclose = (e) => {
            console.log("ONCLOSE ", e);
            client = null;

            openWebSocketConnection();
        };

        client.onmessage = (e) => {

            console.log("ON MESSAGE ", e)

            const res = JSON.parse(get(e, "data"));

            switch (get(res, 'section.key')) {
                case 'procoreProjects':
                    notificationCenter(get(res, 'section.status', true) ? 'success' : 'error', get(res, 'section.status', true) ? 'Your projects list was updated. Please Refresh' : 'Error Updating project list');
                    setSyncAllProjects(false);
                    if (get(res, 'section.status', true))
                        getProcProjects();
                    break;
                default:
                    break;
            }

        };
    };

    const getProcProjects = async (page = 1) => {

        try {

            toggleSpinner(true, 'Getting procore projects');


            const res = await procoreProjects(page);

            if (isEmpty(get(res, 'data', {}))) {
                toggleSpinner(true, 'Syncing procore projects');
            } else {
                toggleSpinner(true, 'Getting procore projects');

                setState(prevState => ({
                    ...prevState,
                    projects: get(res, 'data.projects', []),
                    oldProjects: get(res, 'data.projects', []),
                    counter: get(res, 'data.counter'),
                    totalPages: get(res, 'data.totalPages'),
                    directoryAdminPermissions: get(res, 'data.directoryAdminPermissions')
                }));

                toggleSpinner(false);

            }


        } catch (error) {
            console.log("error ", error)
            onError(error)
        }
    };

    const projectSync = async proj => {

        toggleSpinner(true);

        try {

            const res = await procoreProjectSync(get(proj, 'id'));

            const vendors = {
                warningVendors: get(res, 'data.warningVendors', []),
                successVendors: get(res, 'data.successVendors', [])
            };

            setState(prevState => ({
                ...prevState,
                vendors,
                lastProjectImported: get(proj, 'id'),
            }));

            getProcProjects();
            toggleSpinner(false);

        } catch (e) {
            toggleSpinner(false);
            onError(e.response);
        }
    };


    useEffect(() => {
        settingsContext.onChangeMenu('procore', 0)
        toggleSpinner(true, 'Getting procore projects');
        openWebSocketConnection();

        if (get(props.location, 'state.isImported', false)) {
            projectSync(get(props.location, 'state.procProject'));
            props.history.replace("/procore-projects", undefined);
        } else {
            getProcProjects();
        }
    }, []);

    const settingProjectSettings = async () => {
        try {
            await setProcProjectSettings(state.projectSetting)

            setState(prevState => ({
                ...prevState,
                onSetProjectSettings: false
            }))
        } catch (error) {
            onError(error)
        }
    }

    useEffect(() => {
        if (state.onSetProjectSettings)
            settingProjectSettings()
    }, [state.onSetProjectSettings])


    const onKeepingVendor = (vendor, isKeep) => {

        let newKeepingVendors = remove(get(state.vendors, 'warningVendors', []), item => item.id !== vendor.id);

        set(vendor, 'keep_vendor_status', isKeep);

        setState(prevState => ({
            ...prevState,
            vendors: {
                ...state.vendors,
                warningVendors: newKeepingVendors
            },
            vendorsProcoreDisabled: [...state.vendorsProcoreDisabled, vendor]
        }));
    };

    const onProjectSettingSync = async (projectSettingData) => {

        try {

            setState(prevState => ({
                ...prevState,
                onSetProjectSettings: true,
                projectSetting: {
                    ...prevState.projectSetting,
                    ...projectSettingData
                }
            }));

        } catch (e) {
            onError(e.response)
        }

    };

    const onSummaryClose = () => {
        setState(prevState => ({
            ...prevState,
            projectSummary: {
                state: false,
                data: null
            }
        }))
    };

    const onSearch = () => {

        console.log("Value search ", state.searchValue)



        toggleSpinner(true, 'Searching...')

        const gettingProjects = async () => {
            const res = await searchProcoreProject(state.searchValue)

            setState(prevState => ({
                ...prevState,
                projects: get(res, 'data.projects', []),
                totalPages: get(res, 'data.totalPages'),

            }));

            toggleSpinner(false)
        }

        gettingProjects();


    };

    const onPaginationChange = (event, page) => {
        setState(prevState => ({
            ...prevState,
            page
        }))

        getProcProjects(page)
    }

    const updateProjectList = () => {

        toggleSpinner(true, 'Updating procore projects');
        setSyncAllProjects(true);

        updateProcoreProjects().then(res => {
            toggleSpinner(false);
            notificationCenter('warning', 'You will notified when the update process finish');
        }).catch(err => {
            toggleSpinner(false);
            onError(err.response);
        });

    }

    const onProjectSummary = async (procId, procCompanyId) => {

        try {

            toggleSpinner(true);

            const data = get(await procoreProjectSummary(procId, procCompanyId), 'data');

            setState(prevState => ({
                ...prevState,
                projectSummary: {
                    status: true,
                    data
                }
            }));
            toggleSpinner(false)
        } catch (e) {
            onError(e.response);
        }
    };

    const vendorStatusClose = () => {

        if (size(state.vendorsProcoreDisabled) > 0) {
            setActiveStatusCompanyFromProcore(state.vendorsProcoreDisabled, state.lastProjectImported);
        }

        setState(prevState => ({
            ...prevState,
            vendors: {
                warningVendors: [],
                successVendors: []
            },
            vendorsProcoreDisabled: [],
            vendorsStatus: false,
            vendorsDialogAction: false
        }))

    };

    const onClearSearch = () => {

        setState(prevState => ({
            ...prevState,
            searchValue: ''
        }));
        getProcProjects()
    }

    const onSearchValue = e => {
        const value = e.target.value;

        if (value.length === 0) {
            getProcProjects()
        }


        setState(prevState => ({
            ...prevState,
            searchValue: value
        }))
    }

    const projectImport = proj => {
        if (notValid(get(proj, 'address')) || notValid(get(proj, 'city')) || notValid(get(proj, 'state_code')) || notValid(get(proj, 'zip')) || notValid(get(proj, 'country_code')))
            notificationCenter('error', 'The Address is not correct. Please update project list');
        else
            props.history.push("/sb-projects", { proj })
    };

    return (
        <div>
            <Widget title=' ' logoWidth='150' logo={state.procoreLogo} styleName="jr-card-profile-sm widget-procore-projects" showIntegrationAdmin={get(JSON.parse(localStorage.getItem('userInfo')), 'integrations.procore')}>
                <div className='project-details d-flex align-items-center'>
                    <span ><b>Projects Synced:</b> {get(state, 'counter.imported')}</span>

                    <span className='ml-2 mr-4'><b>Total Projects:</b> {get(state, 'counter.total')}</span>

                    <SearchBox value={state.searchValue} placeholder='Search by name....' onChange={(e) => onSearchValue(e)} onClearSearch={onClearSearch} />

                    <IconButton onClick={onSearch} disabled={isEmpty(state.searchValue) || state.searchValue.length < 2} color="primary" aria-label="upload picture" component="span">
                        <SearchRounded />
                    </IconButton>
                </div>
            </Widget>
            <ProcoreProjectSummary status={state.projectSummary.status}
                projectSummary={state.projectSummary.data}
                onSummaryClose={onSummaryClose} />
            <DialogConfirmation open={state.vendorsStatus}
                onHandleClose={vendorStatusClose}
                disableBackdropClick={true}
                title='Vendors Sync'
                agreeText='Done'
                showActions={false}
                doneAction={state.vendorsDialogAction}
                dialogContent={
                    <ProcoreProjectVendors
                        onKeepingVendor={onKeepingVendor}
                        vendors={state.vendors} />}
            />

            <div style={{ display: 'flex' }}>
                <Button onClick={() => updateProjectList()} className='mb-3' variant="contained" color="primary">
                    Update Project List {syncAllProjects && <CircularProgress color="white" style={{ marginLeft: '10px' }} size={15} />}
                </Button>
            </div>

            {!state.directoryAdminPermissions ?
                <div class="alert alert-warning d-flex align-items-center" role="alert">
                    <Warning style={{ fontSize: '20px', marginRight: '10px' }} />
                    <div style={{ fontSize: '15px' }}>
                        This user doesn't have <i>Admin Directory Permissions</i>
                    </div>
                </div> : null}

            <ProcoreTableComponent
                headers={filter(state.procoreProjTable.headers, header => get(header, 'status'))}>
                <ProcoreProjectsBody
                    projects={state.projects}
                    onProjectSettingSync={onProjectSettingSync}
                    onProjectSummary={onProjectSummary}
                    projectImport={projectImport} />
            </ProcoreTableComponent>

            <Pagination style={{ float: 'right' }} className='mt-4' count={state.totalPages} size="small" onChange={onPaginationChange} />

        </div >

    )
};