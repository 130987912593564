const amHours = [
    {
        value: 0,
        title: '12:00 AM'
    },
    {
        value: 1,
        title: '01:00 AM'
    },
    {
        value: 2,
        title: '02:00 AM'
    },
    {
        value: 3,
        title: '03:00 AM'
    },
    {
        value: 4,
        title: '04:00 AM'
    },
    {
        value: 5,
        title: '05:00 AM'
    },
    {
        value: 6,
        title: '06:00 AM'
    },
    {
        value: 7,
        title: '07:00 AM'
    },
    {
        value: 8,
        title: '08:00 AM'
    },
    {
        value: 9,
        title: '09:00 AM'
    },
    {
        value: 10,
        title: '10:00 AM'
    },
    {
        value: 11,
        title: '11:00 AM'
    }
];
const pmHours = [
    {
        value: 12,
        title: '12:00 PM'
    },
    {
        value: 13,
        title: '01:00 PM'
    },
    {
        value: 14,
        title: '02:00 PM'
    },
    {
        value: 15,
        title: '03:00 PM'
    },
    {
        value: 16,
        title: '04:00 PM'
    },
    {
        value: 17,
        title: '05:00 PM'
    },
    {
        value: 18,
        title: '06:00 PM'
    },
    {
        value: 19,
        title: '07:00 PM'
    },
    {
        value: 20,
        title: '08:00 PM'
    },
    {
        value: 21,
        title: '09:00 PM'
    },
    {
        value: 22,
        title: '10:00 PM'
    },
    {
        value: 23,
        title: '11:00 PM'
    }
];
export const getHoursType = (type) => {
    return type === 'AM' ? amHours : pmHours;
};