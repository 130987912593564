import React, { useState, useEffect, useContext } from 'react';
import { get, filter, isEmpty, pick } from 'lodash';
import { cmicProjects, searchCMICProjects, getCMICSubJobs, saveCMICPerDiem } from '../../../../services/cmicService';
import { SearchBox, Widget } from '../../../../components';
import { Dialog, DialogActions, DialogContent, Button, DialogTitle, IconButton, OutlinedInput, InputAdornment, FormControl, InputLabel, TextField } from '@material-ui/core';
import SearchRounded from '@material-ui/icons/SearchRounded';
import { CMICTableComponent, CMICTableBody, CMICSubJobs } from './index';
import { notificationCenter, notValid } from '../../../../services/appService';

import { SettingsContext } from '../../../../contexts/SettingsContext';
import { AddressAutocomplete, InfinitePagination } from '../../../../components/index'
const W3CWebSocket = require('websocket').w3cwebsocket;

const tableHeaders = [
  {
    title: 'Project #',
    align: 'left',
    status: true
  },
  {
    title: 'Name',
    align: 'left',
    status: true
  },
  {
    title: 'Address',
    align: 'left',
    status: true
  },
  {
    title: 'City',
    align: 'left',
    status: true
  },
  {
    title: 'State',
    align: 'left',
    status: true
  },
  {
    title: 'Last Sync',
    align: 'center',
    status: true
  },
  {
    title: 'Actions',
    align: 'center',
    status: true
  },
  {
    title: 'Per Diem',
    align: 'left',
    status: true
  }
]

export default function CMICProjectList(props) {

  const settingsContext = useContext(SettingsContext);


  const [state, setState] = useState({
    projects: [],
    oldProjects: [],
    searchValue: null,
    page: 1,
    user: JSON.parse(localStorage.getItem('userInfo')),
    importSubJobs: {
      status: false,
      value: null,
      currentProject: null
    },
    perDiem: {
      status: false,
      value: '',
      currentProject: null
    },
    projImportSettings: {
      status: false,
      currentProj: null,
      address: ''
    }
  });

  const toggleSpinner = (spinnerFlag = true, text = 'Loading...') => {
    settingsContext.onChangeLoader(spinnerFlag, text);
  };

  const onError = e => {
    toggleSpinner(false);
    settingsContext.onRequestErrorMessage(e.response);
  };


  const getProjects = async (page = 1) => {
    try {
      console.log("ASD")
      toggleSpinner(true, 'Loading Projects')
      const res = await cmicProjects(page);

      // if (isEmpty(get(res, 'data.projects', {}))) {
      //   toggleSpinner(true, 'Syncing cmic projects');
      // } else {
      setState(prevState => ({
        ...prevState,
        projects: get(res, 'data.projects', []),
        oldProjects: get(res, 'data.projects', [])
      }));
      toggleSpinner(false)
      // }


    } catch (error) {
      onError(error)
    }
  }

  const openWebSocketConnection = () => {
    let client = new W3CWebSocket(`${get(process.env, 'REACT_APP_WSS_CONNECTION')}?userId=${get(state.user, 'user_id')}&companyId=${get(state.user, 'company_id')}`);

    client.onopen = (e) => {
      console.log("OPEN ", e);
    };

    client.onclose = (e) => {
      console.log("ONCLOSE ", e);
      client = null;

      openWebSocketConnection();
    };

    client.onmessage = (e) => {

      console.log("ON MESSAGE ", e)

      const res = JSON.parse(get(e, "data"));

      switch (get(res, 'section.key')) {
        case 'projects':
          notificationCenter('success', 'Your Job list was updated. Please Refresh');
          getProjects();
          break;
        default:
          break;
      }

    };
  };


  const projectImport = proj => {
    if (notValid(get(proj, 'address')) || notValid(get(proj, 'city')) || notValid(get(proj, 'state_code')) || notValid(get(proj, 'zip'))) {
      setState(prevState => ({
        ...prevState,
        projImportSettings: {
          ...prevState.projImportSettings,
          currentProj: proj,
          status: true
        }
      }))
    }

    else {
      props.history.push("/cmic-project-import", { proj })

      // props.history.push("/cmic-projects", { isImported: true, cmicProject: state.cmicProject });
    }
  };

  const onImportProject = () => {

    setState(prevState => ({
      ...prevState,
      projImportSettings: {
        ...prevState.projImportSettings,
        status: false
      }
    }));

    props.history.push("/cmic-project-import", { proj: state.projImportSettings.currentProj, address: state.projImportSettings.address })


  }


  useEffect(() => {
    settingsContext.onChangeMenu('cmic', 0)
    getProjects();
    openWebSocketConnection()
  }, [])

  const onSearchValue = e => {
    const value = e.target.value;

    if (value.length === 0) {
      setState(prevState => ({
        ...prevState,
        projects: prevState.oldProjects
      }));

      return;
    }

    setState(prevState => ({
      ...prevState,
      searchValue: value
    }))
  }

  const onSearch = () => {



    toggleSpinner(true, 'Searching...');

    const gettingProjects = async () => {

      try {
        const res = await searchCMICProjects(state.searchValue)

        setState(prevState => ({
          ...prevState,
          projects: get(res, 'data.projects', [])
        }))

        toggleSpinner(false)
      } catch (error) {
        toggleSpinner(false)

        notificationCenter('error', "Error searching projects")
      }


    }

    gettingProjects();



  };

  const onPaginationChange = (event, page) => {

    console.log(event, page)
    setState(prevState => ({
      ...prevState,
      page
    }))
    getProjects(page)
  }

  const closeEditProjAddress = (flag) => {
    if (!flag) {
      setState(prevState => ({
        ...prevState,
        projImportSettings: {
          status: false,
          address: ''
        }
      }))
    } else {
      console.log(state.projImportSettings.address)
    }

  }

  const onAddressSelected = address => {
    setState(prevState => ({
      ...prevState,
      projImportSettings: {
        ...prevState.projImportSettings,
        address
      }
    }))
  }

  const onClearSearch = () => {

    setState(prevState => ({
      ...prevState,
      searchValue: ''
    }));
    getProjects()
  }


  const onOpenSubJobs = async (projectNumber) => {

    try {

      toggleSpinner(true, 'Getting SubJobs');

      const res = await getCMICSubJobs(projectNumber)

      setState(prevState => ({
        ...prevState,
        importSubJobs: {
          value: get(res, 'data', []),
          currentProject: projectNumber,
          status: true
        }
      }))

      toggleSpinner(false)
    } catch (error) {

    }

  }


  const onPerDiemDialog = (status, proj = null) => {

    setState(prevState => ({
      ...prevState,
      perDiem: {
        ...prevState.perDiem,
        status,
        currentProject: proj,
        value: get(proj, 'perDiemValue', 0)

      }
    }))

  }

  const onCloseSubJobs = () => {
    setState(prevState => ({
      ...prevState,
      importSubJobs: {
        status: false,
        value: []
      }
    }))
  }

  const handlePerDiemChange = (event) => {
    const { value } = event.target;

    const filterValue = value.replace(/[^0-9.]/g, '');
    const splitValue = filterValue.split('.');


    if (splitValue.length === 1) {
      setState(prevState => ({
        ...prevState,
        perDiem: {
          ...prevState.perDiem,
          value: filterValue
        }
      }))
    } else if (splitValue.length === 2) {

      setState(prevState => ({
        ...prevState,
        perDiem: {
          ...prevState.perDiem,
          value: `${splitValue[0]}.${splitValue[1].slice(0, 2)}`
        }
      }))

    }


  };

  const onSavePerDiemValue = async () => {

    try {
      toggleSpinner(true, 'Saving...')
      await saveCMICPerDiem({ perDiemValue: get(state, 'perDiem.value', 0) }, get(state, 'perDiem.currentProject.id', ''))
      // toggleSpinner(false)

      setState(prevState => ({
        ...prevState,
        perDiem: {
          status: false,
          value: '',
          currentProject: null
        }
      }))

      getProjects()

    } catch (error) {
      toggleSpinner(false)

      notificationCenter('error', "Error saving perdiem value")
    }

  }


  return (
    <div>
      <Widget title=' ' logoWidth='130' logo='https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/integrations-logos/cmicLogo.png' styleName="jr-card-profile-sm widget-procore-projects">
        <div className='project-details d-flex align-items-center'>

          <SearchBox placeholder='Search by name....' value={state.searchValue} onChange={(e) => onSearchValue(e)} onClearSearch={onClearSearch} searching={false} />

          <IconButton onClick={onSearch} color="primary" aria-label="upload picture" component="span">
            <SearchRounded />
          </IconButton>
        </div>
      </Widget>

      <CMICTableComponent
        headers={filter(tableHeaders, header => get(header, 'status'))}>
        <CMICTableBody
          projects={state.projects}
          onOpenSubJobs={onOpenSubJobs}
          onPerDiemDialog={onPerDiemDialog}
          projectImport={projectImport} />
      </CMICTableComponent>

      <InfinitePagination page={state.page} pageSize={10} dataSize={state.projects.length} onPaginationChange={onPaginationChange} />

      <Dialog
        open={state.projImportSettings.status}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => closeEditProjAddress(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add project address?"}</DialogTitle>
        <DialogContent>
          <AddressAutocomplete onAddressSelected={onAddressSelected} currentAddress={state.projImportSettings.address} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeEditProjAddress(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={onImportProject} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={state.importSubJobs.status}
        maxWidth="md"
        fullWidth={true}
        onClose={() => onCloseSubJobs(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Sync SubJobs</DialogTitle>
        <DialogContent>
          <CMICSubJobs subs={state.importSubJobs.value} onCloseSubJobs={onCloseSubJobs} projectNumber={state.importSubJobs.currentProject} />
        </DialogContent>
      </Dialog>


      <Dialog
        open={state.perDiem.status}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => onPerDiemDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Per Diem Value</DialogTitle>
        <DialogContent>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={state.perDiem.value}
              onChange={handlePerDiemChange}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              labelWidth={60}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onPerDiemDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={onSavePerDiemValue} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

    </div >
  )
};