import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, TextField } from '@material-ui/core';
import {
  SBSwitch
} from '../../../../components/index'
import { notificationCenter } from '../../../../services/appService';
import { get, trim } from 'lodash';
import { addCmicSettings, getCmicSettings } from '../../../../services/cmicService'
import { isEmpty } from 'lodash';

export default function CMICSettings(props) {

  const { open, onCloseCMICSettings, toggleSpinner } = props;

  const [state, setState] = useState({
    credentials: {
      url: '',
      username: '',
      password: ''
    },
    settings: {
      phaseCode: '',
      subJobCodeParser: '.',
      companyIdCode: '',
      approved: true,
      submitted: true,
      enablePerDiem: false
    }
  });

  const gettingSettings = async () => {
    // try{
    const res = await getCmicSettings();
    setState(prevState => ({
      ...prevState,
      settings: { ...get(res, 'data.settings') },
      credentials: { ...get(res, 'data.credentials') }
    }))
    // } catch (error) {
    //   notificationCenter("error", "Error getting settings");
    // }
  }

  useEffect(() => {
    gettingSettings()
  }, [])

  const onChangeSettings = (type, value) => {
    setState(prevState => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        [type]: value
      }

    }))
  }

  const onChangeCredentials = (type, value) => {
    setState(prevState => ({
      ...prevState,
      credentials: {
        ...prevState.credentials,
        [type]: value
      }

    }))
  }

  const sendSettings = async () => {
    try {
      if (isEmpty(state.credentials.password) || isEmpty(state.credentials.url) || isEmpty(state.credentials.username) || isEmpty(state.settings.companyIdCode)) {
        notificationCenter('error', 'Some fields are required')
      } else {
        toggleSpinner(true);
        await addCmicSettings({ ...state });
        toggleSpinner(false)
        onCloseCMICSettings()
      }

    } catch (error) {
      notificationCenter('error', 'Error saving settings')
    }
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <img width='50px' height='auto' className="mb-3" src="https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/integrations-logos/cmicLogo.png" alt="Integration Logo" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ fontWeight: 'bold' }}>
          Please add your CMIC's credentials
        </DialogContentText>

        <TextField
          onChange={e => onChangeCredentials('url', e.target.value)}
          id="url"
          value={state.credentials.url}
          label="Url"
          type="text"
          fullWidth
        />

        <TextField
          onChange={e => onChangeCredentials('username', e.target.value)}
          id="username"
          value={state.credentials.username}
          label="Username"
          type="text"
          fullWidth
        />


        <TextField
          onChange={e => onChangeCredentials('password', e.target.value)}
          id="password"
          value={state.credentials.password}
          label="Password"
          type="password"
          fullWidth
        />

        <DialogContentText style={{ fontWeight: 'bold', marginTop: '25px' }}>
          Please add your CMIC's settings
        </DialogContentText>

        <TextField
          onChange={e => onChangeSettings('phaseCode', trim(e.target.value))}
          id="phaseCode"
          value={state.settings.phaseCode}
          placeholder='Format: XX,YY,WW'
          label="Category Codes"
          type="text"
          fullWidth
        />

        <TextField
          required
          onChange={e => onChangeSettings('companyIdCode', trim(e.target.value))}
          id="companyIdCode"
          value={state.settings.companyIdCode}
          // placeholder='Format: XX,YY,WW'
          label="Company Id Code"
          type="text"
          fullWidth
        />

        <TextField
          // required
          onChange={e => onChangeSettings('subJobCodeParser', trim(e.target.value))}
          id="jobCodeParser"
          value={state.settings.subJobCodeParser}
          label="SubJob Number Separator"
          type="text"
          fullWidth
        />

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <FormControlLabel
            control={<SBSwitch checked={state.settings.approved} handleToggle={e => onChangeSettings(e.target.name, e.target.checked)} name="approved" />}
            label='Approved'
            style={{ marginTop: '10px' }}
          />

          <FormControlLabel
            control={<SBSwitch checked={state.settings.submitted} handleToggle={e => onChangeSettings(e.target.name, e.target.checked)} name="submitted" />}
            label='Submitted'
            style={{ marginTop: '10px' }}
          />

          <FormControlLabel
            control={<SBSwitch checked={state.settings.enablePerDiem} handleToggle={e => onChangeSettings(e.target.name, e.target.checked)} name="enablePerDiem" />}
            label='Enable Per Diem'
            style={{ marginTop: '10px' }}
          />


        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCloseCMICSettings}>
          Cancel
        </Button>
        <Button color="primary" onClick={sendSettings}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
