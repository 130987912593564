import React from 'react';
import PropTypes from "prop-types";
import {get} from 'lodash';

const StatusField = (props) => {

    return (
        <b style={{color: get(props , 'color')}}>{get(props , 'text')}</b>
    );

};

StatusField.propTypes = {
    color: PropTypes.string,
    text: PropTypes.string
};

StatusField.defaultProps = {
    color: "red",
    text: 'Required Field'
};

export default StatusField;
