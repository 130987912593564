import React, { useState, useEffect, useContext } from "react";
import { get, isEmpty } from 'lodash';
import Button from '@material-ui/core/Button';

import {
    sbProjects,
    importProcoreProject,
    setProcProjectSettings
} from '../../../../services/integrationService';

import '../../../../static/styles/components/_procoreProjectImport.scss';

import { Widget } from "../../../../components";
import { SettingsContext } from "../../../../contexts/SettingsContext";

const procProjectInfo = (icon, title, text) => {
    return (
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
            <div className="media align-items-center flex-nowrap jr-pro-contact-list">
                <div className="mr-3">
                    <i className={`zmdi ${icon} jr-fs-xxl text-orange`} />
                </div>
                <div className="media-body">
                    <span className="mb-0 text-grey jr-fs-sm">{title}</span>
                    <p className="mb-0">{text}</p>
                </div>
            </div>
        </div>
    );
};

const sbProjectInfo = (title, text) => {
    return (
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="media align-items-center flex-nowrap jr-pro-contact-list">
                <div className="media-body">
                    <span className="mb-0 text-grey jr-fs-sm">{title}</span>
                    <p className="mb-0">{text}</p>
                </div>
            </div>
        </div>
    );
};



export default function ProcoreProjects(props) {

    const settingsContext = useContext(SettingsContext);


    const [state, setState] = useState({
        sbProjects: [],
        procProject: null
    });


    const toggleSpinner = (spinnerFlag = true) => {
        settingsContext.onChangeLoader(spinnerFlag)
    };

    const onError = e => {
        settingsContext.onRequestErrorMessage(e)
    };

    // const setUpDailySyncDefault = async () => {

    //     try {
    //         const payload = {
    //             type: 'daily_sync',
    //             procId: get(state.procProject, 'id'),
    //             data: {
    //                 AM: {
    //                     status: true,
    //                     time: 9
    //                 },
    //                 PM: {
    //                     status: true,
    //                     time: 18
    //                 }
    //             }
    //         };

    //         return await setProcProjectSettings(payload);
    //     } catch (e) {
    //         onError(e.response)
    //     }
    // };

    const onProjectSelected = async (sbProject) => {

        try {
            toggleSpinner(true);

            const obj = {
                "isNew": false,
                "procId": get(state.procProject, 'id'),
                "projId": get(sbProject, '_id'),
            };

            await importProcoreProject(obj);

            // await setUpDailySyncDefault();

            props.history.push("/procore-projects", { isImported: true, procProject: state.procProject });

        } catch (e) {
            onError(e.response);
        }
    };

    const syncNewProj = async () => {

        try {

            toggleSpinner(true);
            const obj = {
                isNew: true,
                procId: get(state.procProject, 'id'),
                procProj: state.procProject
            };

            await importProcoreProject(obj);

            // await setUpDailySyncDefault();

            props.history.push("/procore-projects", { isImported: true, procProject: state.procProject });

        } catch (e) {
            onError(e.response);
        }
    };

    const gettingSbProjects = async (projProc) => {

        try {

            const procAddress = {
                address: get(projProc, 'address'),
                city: get(projProc, 'city'),
                state: get(projProc, 'state_code'),
                zip: get(projProc, 'zip')
            };

            toggleSpinner(true);

            const res = await sbProjects(procAddress);

            setState(prevState => ({
                ...prevState,
                sbProjects: get(res, 'data')
            }))

            toggleSpinner(false)

        } catch (error) {
            onError(error.response)
        }

    }

    useEffect(() => {

        settingsContext.onChangeMenu("")
        const projProc = get(props.location, 'state.proj');
        setState(prevState => ({
            ...prevState,
            procProject: projProc
        }));
        gettingSbProjects(projProc)
    }, [])

    return (
        <div>
            <Widget title='Procore Project INFO.' styleName="jr-card-profile-sm">
                <div className='row'>
                    {procProjectInfo('zmdi-city-alt', 'Name', get(state.procProject, 'name'))}
                    {procProjectInfo('zmdi-gps-dot', 'Address', `${get(state.procProject, 'address')} ${get(state.procProject, 'city')}, ${get(state.procProject, 'state_code')} ${get(state.procProject, 'zip')}`)}
                    {procProjectInfo('zmdi-alert-triangle', 'Project #', get(state.procProject, 'project_number', '-'))}
                    {procProjectInfo('zmdi-city-alt', 'Company', get(state.procProject, 'company.name', '-'))}
                </div>
            </Widget>

            <Button onClick={() => syncNewProj()} variant="contained"
                className="jr-btn bg-success text-white mb-4">Import as new project</Button>

            {!isEmpty(state.sbProjects) &&
                <h2>Merge with this Project</h2>}

            {state.sbProjects.map((sbProj, idx) => (
                <div key={idx} className="procore-import-project mb-4">

                    <Button style={{ width: '100px' }}
                        onClick={() => onProjectSelected(sbProj)} variant="contained"
                        className="jr-btn bg-success text-white">Merge</Button>

                    <Widget title={get(sbProj, 'name')} styleName="jr-card-profile-sm card-import-project">

                        <div className='row'>
                            {sbProjectInfo('Address', `${get(sbProj, 'address')} ${get(sbProj, 'city')}, ${get(sbProj, 'state')} ${get(sbProj, 'zip')}`)}
                            {sbProjectInfo('Distance', `${get(sbProj, 'distance')} miles`)}
                            {sbProjectInfo('Latitude', get(sbProj, 'latitude'))}
                            {sbProjectInfo('Longitude', get(sbProj, 'longitude'))}
                        </div>
                    </Widget>
                </div>
            ))}
        </div>
    )
};