import React from 'react';
import {
    AppBar,
    Toolbar,
    makeStyles
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {Link} from "react-router-dom";
import {get} from 'lodash';

const useStyles = makeStyles(theme => ({
    menuButton: {
        paddingBottom: "8px",
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    }
}));


const HeaderSB = (props) => {

    const classes = useStyles();

    return (
        <AppBar className="app-main-header" position="static">
            <Toolbar className="app-toolbar" disableGutters={false}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={(e) => props.onMenu(e)}
                    className={classes.menuButton}>
                    <MenuIcon/>
                </IconButton>
                <a className="mr-3 d-sm-block" href={get(process.env , 'REACT_APP_BASE_DASHBOARD_URL')}>
                    <i className="zmdi zmdi-home icon-home-dashboard"/>
                </a>
                <Link className="app-logo mr-2 d-sm-block" to="/home">
                    <img src={props.logoPath} width="100" height="auto" alt="SmartBarrel" title="SmartBarrel"/>
                </Link>
            </Toolbar>
        </AppBar>
    )
};

HeaderSB.defaultProps = {
    logoPath: "https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/images/logo/SmartBarrel_White.png"
};

export default HeaderSB;