import React from "react";
import {DialogConfirmation} from "../../../../components";

import {get, find} from 'lodash';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';

class ProcoreDialogConfirmation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            companySelection: {
                value: '',
                status: true
            },

            companyConfirmation: {
                text: null,
                status: false
            }
        };

        this.procoreData = get(this.props, 'procore');
    }

    companyById = () => {
        return find(get(this.procoreData, 'procoreCompanies'), item => get(item, 'id') === this.state.companySelection.value);
    };

    handleCompanySelect = (e) => {
        this.setState(prevState => ({
            ...prevState,
            companySelection: {
                status: true,
                value: e
            }
        }))
    };

    handleClose = async (isAgree, isCompanyConfirmation = false) => {

        switch (true) {
            case !isAgree:
                this.setState(prevState => ({
                    ...prevState,
                    companyConfirmation: {
                        status: false,
                        value: null
                    },
                    companySelection: {
                        status: false,
                        value: ''
                    }
                }), () => {
                    this.props.onCompanyConfirmation(this.companyById(), false);
                });
                break;
            case !isCompanyConfirmation:
                const confirmationContent = () => {

                    return (
                        <h4>
                            <b>{get(this.companyById(), 'name')}</b> from Procore will be synchronized
                            with <b>{get(this.props.procore, 'sb_company.name')}</b> from SmartBarrel
                        </h4>
                    );
                };

                this.setState(prevState => ({
                    ...prevState,
                    companySelection: {
                        status: false,
                        value: prevState.companySelection.value
                    },
                    companyConfirmation: {
                        status: true,
                        text: confirmationContent()
                    }
                }));
                break;
            default:
                this.setState(prevState => ({
                    ...prevState,
                    companyConfirmation: {
                        status: false,
                        value: null
                    }
                }), () => this.props.onCompanyConfirmation(this.companyById(), true));
                break;
        }

    };

    creatingCompanySelect = () => {

        return (
            <FormControl className='company-select-procore'>
                <InputLabel id="demo-simple-select-error-label">Select</InputLabel>
                <Select onChange={(e) => this.handleCompanySelect(e.target.value)}
                        value={this.state.companySelection.value}>
                    {this.procoreData.procoreCompanies.map(item => (
                        <MenuItem key={get(item, 'id')} value={get(item, 'id')}>{get(item, 'name')}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    render() {
        return (
            <div>
                <DialogConfirmation open={this.state.companySelection.status}
                                    onHandleClose={(e) => this.handleClose(e, false)}
                                    title='Select Company'
                                    agreeText='Next'
                                    disagreeText='Cancel'
                                    dialogContent={this.creatingCompanySelect()}/>
                <DialogConfirmation open={this.state.companyConfirmation.status}
                                    onHandleClose={(e) => this.handleClose(e, true)}
                                    title='Are these the same Company?'
                                    agreeText='Confirm'
                                    disagreeText='Back'
                                    dialogContent={this.state.companyConfirmation.text}/>
            </div>
        );
    };

}

export default ProcoreDialogConfirmation;