import React, { useState, useContext } from "react";

import { UserInfo } from '../index';

import {
    Divider,
    Drawer,
    Hidden,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@material-ui/core';

import '../../static/styles/components/_drawer.scss';

import { makeStyles, useTheme } from "@material-ui/core/styles";
import { get } from 'lodash';

import { AuthContext } from "../../contexts/AuthContext";
import { SettingsContext } from "../../contexts/SettingsContext";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    appBar: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    }
}));

const DrawerContent = ({ classes, userInfo, settingContext }) => {

    return (
        <div>
            <div className={classes.toolbar} >
                <UserInfo user={userInfo} />
            </div>

            <Divider />
            <List>
                {settingContext.currentMenu.map(menu => (
                    <ListItem
                        selected={menu.active}
                        button
                        key={menu.idx}
                        onClick={e => settingContext.handleMainMenuLeft(menu, true)}
                    >
                        <ListItemIcon selected={true}>
                            <i className={get(menu, 'icon', 'zmdi zmdi-balance text-orange')} />
                        </ListItemIcon>
                        <ListItemText primary={menu.type} />
                    </ListItem>
                ))}
            </List>
        </div>
    )
};

export default function LeftMenu(props) {

    const classes = useStyles();
    const theme = useTheme();

    const authContext = useContext(AuthContext);
    const settingContext = useContext(SettingsContext);

    return (
        <div className={classes.root}>
            <nav className={classes.drawer}>
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === "rtl" ? "right" : "left"}
                        open={props.hideLeftMenu}
                        onClose={(e) => props.onMenu(e)}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        ModalProps={{
                            keepMounted: true
                        }}
                    >
                        <DrawerContent classes={classes} userInfo={authContext.userInfo} settingContext={settingContext} />
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        variant="permanent"
                        open>
                        <DrawerContent classes={classes} userInfo={authContext.userInfo} settingContext={settingContext} />
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    );
};