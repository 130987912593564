import React, { useState } from 'react';
import { get, lowerCase } from 'lodash';

import '../../static/styles/components/_cardPresentational.scss';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { changingStatus, switchingUser } from '../../services/integrationService';
import { notificationCenter } from '../../services/appService';
import { split } from 'lodash';


const ITEM_HEIGHT = 48;

const MenuComponent = (props) => {

    const { integration, toggleSpinner, userConfig, initialSettings, cmicSettings, onShowIntegrationInfo } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [options, setOptions] = useState(get(integration, 'isDisabled') ? ['Enable'] : ['Disabled'])
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSettings = () => {

        switch (get(integration, 'key')) {
            case "cmic":
                cmicSettings(integration);
                break;
            default:
                break;
        }


    }

    const onOptionSelected = async (option) => {
        try {

            switch (option) {
                case "Enable":
                    toggleSpinner();
                    setAnchorEl(null);
                    await changingStatus(lowerCase(option), get(integration, 'key'));
                    window.location.reload();
                    toggleSpinner(false);
                    break;
                case "Disable":
                    toggleSpinner();
                    setAnchorEl(null);
                    await changingStatus(lowerCase(option), get(integration, 'key'));
                    window.location.reload();
                    toggleSpinner(false);
                    break;
                case "Switch User":
                    toggleSpinner();
                    setAnchorEl(null);
                    await switchingUser();
                    window.location.reload();
                    toggleSpinner(false);
                    break;
                case "Settings":
                    onSettings();
                    break;
                case "Integration Info.":
                    onShowIntegrationInfo(integration);
                    break;
                case "Import Workers":
                    // toggleSpinner();
                    setAnchorEl(null);
                    // await switchingUser();
                    window.location = '/cmic-import-workers';
                    toggleSpinner(false);
                    break;
                default:
                    break;
            }


        } catch (error) {

        }

    }

    return (
        <div style={{ position: 'absolute', right: '0', top: '0' }}>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon style={{ color: 'white' }} />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {get(integration, 'menuOptions', []).map((option) => (
                    <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => onOptionSelected(option)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}


const DescriptionFormat = (props) => {

    const { desc } = props;
    // let liData  = get(desc , 'type') === p ? [] : 

    let formatData = {
        'p': () => <p className="card-text">{get(desc, 'info', '')}</p>,
        'ul': () => {
            let liData = split(get(desc, 'info', ''), '&')

            return (
                <ul style={{ textAlign: 'left', paddingLeft: '9px' }}>
                    {liData.map(item => (
                        <li>{item}</li>
                    ))}
                </ul>
            )
        }
    }

    console.log();

    return (
        formatData[get(desc, 'type')]()
    )



}

const CardPresentational = (props) => {

    const { integration, toggleSpinner, onIntegrationDetails, onActiveIntegration, userConfig, initialSettings, onActiveCMIC, cmicSettings, onShowIntegrationInfo } = props;

    const isActive = () => {
        return get(integration, 'isActive') ? 'active' : 'inactive';
    };




    return (
        <div className="jr-card text-center card-presentational" style={{ position: 'relative' }}>
            <div className={`jr-card-header-color  bg-gradient-${isActive()} ${get(integration, 'isActive') ? 'pointer' : ''}`}>
                {get(integration, 'isActive') ?
                    <MenuComponent integration={integration} cmicSettings={cmicSettings} toggleSpinner={toggleSpinner} userConfig={userConfig} initialSettings={initialSettings} onShowIntegrationInfo={onShowIntegrationInfo} /> : null
                }
                <div className='integration-logo'>
                    <img width='100px' height='auto' className="mb-3" src={get(integration, 'icon')}
                        alt="Integration Logo" />
                </div>

                <div className="jr-card-hd-content">
                    {get(integration, 'isActive') && !get(integration, 'tokenExpired') ?
                        <a onClick={() => onIntegrationDetails(integration)}
                            variant="contained"
                            style={{ backgroundColor: '#828282' }}
                            className="jr-btn text-white">Details</a> : null}

                    {!get(integration, 'isActive') && !get(integration, 'tokenExpired') ?
                        <a onClick={() => get(integration, 'key') === 'cmic' ? onActiveCMIC() : onActiveIntegration(integration)}
                            variant="contained"
                            className="jr-btn bg-success text-white">Activate</a> : null}

                    {get(integration, 'tokenExpired') ?
                        <a onClick={() => onActiveIntegration(integration)}
                            variant="contained"
                            className="jr-btn bg-success text-white">Refresh</a> : null}
                </div>

            </div>
            <div className="jr-card-body pt-2">
                <DescriptionFormat desc={get(integration, 'desc')} />
                {/* <p className="card-text">{get(integration, 'desc')}</p> */}
            </div>
        </div>
    )
};

export default CardPresentational;

