import React, { useState, useEffect, useContext } from 'react';
import { cmicSBProjects, importCMICProjects } from '../../../../services/cmicService';
import { get, isEmpty, isUndefined } from 'lodash';
import { Widget } from '../../../../components';
import Button from '@material-ui/core/Button';
import { SettingsContext } from '../../../../contexts/SettingsContext';

const sbProjectInfo = (title, text) => {
  return (
    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
      <div className="media align-items-center flex-nowrap jr-pro-contact-list">
        <div className="media-body">
          <span className="mb-0 text-grey jr-fs-sm">{title}</span>
          <p className="mb-0">{text}</p>
        </div>
      </div>
    </div>
  );
};


const cmicProjectInfo = (icon, title, text) => {
  return (
    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
      <div className="media align-items-center flex-nowrap jr-pro-contact-list">
        <div className="mr-3">
          <i className={`zmdi ${icon} jr-fs-xxl text-orange`} />
        </div>
        <div className="media-body">
          <span className="mb-0 text-grey jr-fs-sm">{title}</span>
          <p className="mb-0">{text}</p>
        </div>
      </div>
    </div>
  );
};


export default function CMICProjectImport(props) {

  const settingsContext = useContext(SettingsContext);

  const [state, setState] = useState({
    sbProjects: [],
    cmicProject: null
  });

  const toggleSpinner = (spinnerFlag = true, text = 'Loading...') => {
    settingsContext.onChangeLoader(spinnerFlag, text);
  };

  const onError = e => {
    toggleSpinner(false);
    settingsContext.onRequestErrorMessage(e.response);
  };

  const getSbProjectList = async (data) => {

    try {
      toggleSpinner(true);

      const res = await cmicSBProjects(data);

      setState(prevState => ({
        ...prevState,
        sbProjects: get(res, 'data')
      }));
      toggleSpinner(false);

    } catch (error) {
      onError(error);
    }

  }


  useEffect(() => {

    const projCMIC = get(props.location, 'state.proj');
    setState(prevState => ({
      ...prevState,
      cmicProject: projCMIC
    }));

    getSbProjectList({ projAddress: get(props.location, 'state.address') });

  }, [])

  const syncNewProj = async () => {

    try {

      toggleSpinner(true);
      const obj = {
        isNew: true,
        cmicProjectId: get(state.cmicProject, 'id'),
        cmicProject: {
          ...state.cmicProject,
          address: get(props.location, 'state.address')
        }
      };

      await importCMICProjects(obj);

      props.history.push("/cmic-projects", { isImported: true, cmicProject: state.cmicProject });

    } catch (e) {
      onError(e.response);
    }
  };

  const onProjectSelected = async (sbProject) => {

    try {
      toggleSpinner(true);

      const obj = {
        "isNew": false,
        "cmicProjectId": get(state.cmicProject, 'id'),
        "projId": get(sbProject, '_id'),
      };

      await importCMICProjects(obj);
      props.history.push("/cmic-projects", { isImported: true, cmicProject: state.cmicProject });

    } catch (e) {
      onError(e.response);
    }
  };

  return (
    <div>

      <Widget title='Procore Project INFO.' styleName="jr-card-profile-sm">
        <div className='row'>
          {cmicProjectInfo('zmdi-city-alt', 'Name', get(state.cmicProject, 'name'))}
          {cmicProjectInfo('zmdi-gps-dot', 'Address', `${isUndefined(get(props.location, 'state.address')) ? get(props.location, 'state.proj.address') : get(props.location, 'state.address')}`)}
          {cmicProjectInfo('zmdi-alert-triangle', 'Project #', get(state.cmicProject, 'project_number', '-'))}
          {cmicProjectInfo('zmdi-city-alt', 'Company', get(state.cmicProject, 'company.name', '-'))}
        </div>
      </Widget>

      <Button onClick={() => syncNewProj()} variant="contained"
        className="jr-btn bg-success text-white mb-4">Import as new project</Button>

      {!isEmpty(state.sbProjects) &&
        <h2>Merge with this Project</h2>}

      {state.sbProjects.map((sbProj, idx) => (
        <div key={idx} className="procore-import-project mb-4">

          <Button style={{ width: '100px' }}
            onClick={() => onProjectSelected(sbProj)} variant="contained"
            className="jr-btn bg-success text-white">Merge</Button>

          <Widget title={get(sbProj, 'name')} styleName="jr-card-profile-sm card-import-project">

            <div className='row'>
              {sbProjectInfo('Address', `${get(sbProj, 'address')} ${get(sbProj, 'city')}, ${get(sbProj, 'state')} ${get(sbProj, 'zip')}`)}
              {sbProjectInfo('Distance', `${get(sbProj, 'distance')} miles`)}
              {sbProjectInfo('Latitude', get(sbProj, 'latitude'))}
              {sbProjectInfo('Longitude', get(sbProj, 'longitude'))}
            </div>
          </Widget>
        </div>
      ))}
    </div>

  )
};