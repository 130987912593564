import React, { useState, useEffect, useContext } from 'react';
import * as _ from 'lodash';
import axios from 'axios';
import '../../static/styles/components/_integrations.scss';

import {
    CardPresentational, IntegrationGeneralInfo
} from '../../components/index';

import {
    activateIntegration,
    getIntegrations,
    getUserProfile,
    cmicSetUpService
} from '../../services/integrationService'

import {
    CMICSetup,
    CMICSettings
} from './Components/CMIC/index';

import { notificationCenter } from '../../services/appService';
import { SettingsContext } from '../../contexts/SettingsContext';
import { AuthContext } from '../../contexts/AuthContext';


export default function Integration(props) {

    const settingsContext = useContext(SettingsContext);
    const authContext = useContext(AuthContext);


    const [state, setState] = useState({
        userConfig: {},
        cmicIntegration: {
            settings: {
                status: false,
                data: {}
            },
            open: false,
            credentials: {
                url: '',
                username: '',
                password: ''
            }
        },
        integrationInfo: {
            status: false,
            data: null
        }
    });

    const toggleSpinner = (spinnerFlag = true) => {
        settingsContext.onChangeLoader(spinnerFlag)
    };


    const initialSettings = async () => {
        try {

            const integrations = await getIntegrations();
            settingsContext.onSetIntegrations(_.get(integrations, 'data.integrations'))
            const res = await getUserProfile();

            console.log("ASDASDAS ", res.data)
            authContext.onUserInfo(res.data);
            setState(prevState => ({
                ...prevState,
                userConfig: _.get(res, 'data.user')
            }))
            toggleSpinner(false)
        } catch (error) {
            settingsContext.onRequestErrorMessage(error.response)
        }
    }

    useEffect(() => {

        if (_.get(props, 'match.path') === '/embedded/:sessionId?') {
            window.location = '/procore-projects'
        }
        settingsContext.onChangeMenu('main');
        authContext.onSetToken(props);
        initialSettings();

    }, []);

    const onIntegrationDetails = (integration) => {


        if (_.get(integration, 'isDisabled')) {
            notificationCenter('warning', "This integration is disabled!");
        } else {

            switch (_.get(integration, 'key')) {
                case 'procore':
                    window.location.href = '/procore-projects';
                    break;
                case 'cmic':
                    window.location.href = '/cmic-projects';
                    break;
                case 'adp':
                    window.location.href = '/adp-settings';
                    break;
                default:
                    break;
            }
        }
    };



    const onActiveIntegration = async (integration) => {
        if (_.get(integration, 'isDisabled')) {
            notificationCenter('warning', "This integration is disabled!");
        } else {
            console.log(_.get(integration, 'key') === 'procore', ' ', _.get(integration, 'tokenExpired'), ' ', state.userConfig)
            if (_.get(integration, 'key') === 'procore' && _.get(integration, 'tokenExpired') && !_.get(state.userConfig, 'integrationPermissions.procore')) {
                notificationCenter('error', "This user doesn't have permissions. Contact us!");
            } else {
                await activateIntegration(integration);
            }
        }


    };

    const onActiveCMIC = () => {

        setState(prevState => ({
            ...prevState,
            cmicIntegration: {
                ...prevState.cmicIntegration,
                open: true
            }
        }))

    }

    const onChangeCMICInitialData = (type, value) => {

        setState(prevState => ({
            ...prevState,
            cmicIntegration: {
                ...prevState.cmicIntegration,
                credentials: {
                    ...prevState.cmicIntegration.credentials,
                    [type]: value
                }
            }
        }))

    }



    const addCMICIntegration = async () => {

        if (_.isEmpty(state.cmicIntegration.credentials.password) || _.isEmpty(state.cmicIntegration.credentials.url) || _.isEmpty(state.cmicIntegration.credentials.username)) {
            notificationCenter('error', "All fields are require!");

        } else {
            console.log("QUE BOLA")
            try {
                toggleSpinner(true)
                await cmicSetUpService(state.cmicIntegration.credentials)
                window.location.href = '/cmic-projects';

            } catch (error) {
                notificationCenter('error', "Error!");

            }
        }
    }

    const handleCloseCMICSettings = () => {
        setState(prevState => ({
            ...prevState,
            cmicIntegration: {
                ...prevState.cmicIntegration,
                open: false
            }
        }))
    }

    const cmicSettings = (integration) => {
        setState(prevState => ({
            ...prevState,
            cmicIntegration: {
                ...prevState.cmicIntegration,
                settings: {
                    ...prevState.cmicIntegration.settings,
                    status: true
                }
            }
        }))
    }

    const onCloseCMICSettings = () => {
        setState(prevState => ({
            ...prevState,
            cmicIntegration: {
                ...prevState.cmicIntegration,
                settings: {
                    ...prevState.cmicIntegration.settings,
                    status: false
                }
            }
        }))
    }

    const onShowIntegrationInfo = (data = null, status = true) => {

        setState(prevState => ({
            ...prevState,
            integrationInfo: {
                status,
                data
            }
        }))
    }

    return (

        <div className='integration-list-container'>
            <div className='integration-cards d-flex flex-wrap'>
                {settingsContext.integrations.map(integration => (
                    <CardPresentational
                        onIntegrationDetails={onIntegrationDetails}
                        onShowIntegrationInfo={onShowIntegrationInfo}
                        onActiveCMIC={onActiveCMIC}
                        onActiveIntegration={onActiveIntegration}
                        key={_.get(integration, 'key')}
                        integration={integration}
                        userConfig={authContext.userInfo}
                        toggleSpinner={toggleSpinner}
                        cmicSettings={cmicSettings}
                    />
                ))}
            </div>
            {/* CMIC Setup */}
            <CMICSetup open={state.cmicIntegration.open} onChangeCMICInitialData={onChangeCMICInitialData} handleCloseCMICSettings={handleCloseCMICSettings} addCMICIntegration={addCMICIntegration} />
            {state.cmicIntegration.settings.status ? <CMICSettings open={state.cmicIntegration.settings.status} onCloseCMICSettings={onCloseCMICSettings} toggleSpinner={toggleSpinner} /> : null}


            <IntegrationGeneralInfo open={state.integrationInfo.status} integration={state.integrationInfo.data} onShowIntegrationInfo={onShowIntegrationInfo} />

        </div>
    )
};
