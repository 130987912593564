import React, { useState } from "react";
import { notificationCenter } from "../../../../../services/appService";

import { get, cloneDeep, isNull } from 'lodash';
import { DatePicker, Form } from 'antd';
import '../../../../../static/styles/components/_payrollFilters.scss'

const { RangePicker } = DatePicker;



const DateRangeFilterComponent = ({ onFilterChange }) => {

  const [dateValue, setDateValue] = useState(
    []
  );


  const onLocalChange = (value) => {

    const _from = cloneDeep(value[0]);
    const _to = cloneDeep(value[1]);

    if (_from.isAfter() || _to.isAfter()) {
      notificationCenter('error', "Can't select dates in the future!")

    } else {
      setDateValue(value);
      onFilterChange('dateRange', { _from: _from.format('LL'), _to: _to.format('LL') })
    }



  };
  return <Form.Item label="Date Range">

    <RangePicker
      format="MM/DD/YYYY"
      value={dateValue}
      className="range-picker-filter"
      onChange={(event) => onLocalChange(event)}
    />
  </Form.Item>

};


export default DateRangeFilterComponent;