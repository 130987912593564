import React, { Component } from 'react';
import {
    notificationCenter
} from '../../../../services/appService';

import {
    Switch,
    Select,
    MenuItem,
    Slide,
    Button,
    CircularProgress
} from '@material-ui/core';

import jsonLogic from 'json-logic-js';

import { CardBox, TooltipInfo } from "../../../../components";
import { find, get, set } from 'lodash';

import '../../../../static/styles/components/_dailyLogSetting.scss';
import { setProcProjectSettings } from '../../../../services/integrationService';


export default class ResourceManagementSetting extends Component {



    constructor(props) {
        super(props);

        this.state = {
            timeCards_sync: {
                syncSubContractorWorkers: get(this.props.procProj, 'timeCards_sync.syncSubContractorWorkers', false),
                time_entry: get(this.props.procProj, 'timeCards_sync.time_entry', 'total_hours'),
                dailyTimeSync: get(this.props.procProj, 'timeCards_sync.dailyTimeSync', false),
                filters: get(this.props.procProj, 'timeCards_sync.filters', [
                    {
                        name: 'approved',
                        value: false
                    }
                ]
                ),

            },
            costCodeSettings: {
                costCode_sync: get(this.props.procProj, 'costCode_sync.status', false),
                import_costCode_sync: get(this.props.procProj, 'import_costCode_sync.status', false),
                budgeted_costCode_sync: get(this.props.procProj, 'budgeted_costCode_sync.status', false),
            },
            otherSettingsDialog: false,
            onSyncingStatus: false
        }
    }

    handleDailyChange = (event, name) => {

        const checked = event.target.checked;

        this.setState({
            ...this.state,
            timeCards_sync: {
                ...this.state.timeCards_sync,
                [name]: {
                    ...this.state.timeCards_sync[name],
                    status: checked
                }
            }
        });

    };

    handleFilterChange = (event, name) => {

        const checked = event.target.checked;

        const filters = this.state.timeCards_sync.filters.map(item => {
            if (get(item, 'name') === name) set(item, 'value', checked);

            return item;
        })


        this.setState(prevState => ({
            ...prevState,
            timeCards_sync: {
                ...prevState['timeCards_sync'],
                filters
            }
        }))
    };

    handSwitchChange = (event, type) => {

        const checked = event.target.checked;


        this.setState({
            ...this.state,
            [type]: checked
        });
    };


    handCostCodeSwitchChange = (event, type) => {

        const checked = event.target.checked;

        const newSwitches = Object.keys(this.state.costCodeSettings).reduce((newState, key) => {
            newState[key] = false;
            return newState;
        }, {});

        this.setState({
            ...this.state,
            costCodeSettings: {
                ...newSwitches,
                [type]: checked
            }
        });

    };


    onTimeSelect = (e, logType) => {

        const value = e.target.value;

        this.setState({
            ...this.state,
            timeCards_sync: {
                ...this.state.timeCards_sync,
                [logType]: {
                    ...this.state.timeCards_sync[logType],
                    value: {
                        hour: value,
                        minute: 0
                    }
                }
            }
        });
    };

    getFilterValue = (key) => {

        const filter = find(this.state.timeCards_sync.filters, filter => get(filter, 'name') === key);

        return get(filter, 'value', false)

    };

    onSyncSubContractorWorkers = (e) => {
        this.setState(prevState => ({
            ...prevState,
            timeCards_sync: {
                ...prevState.timeCards_sync,
                syncSubContractorWorkers: e
            }
        }))
    }


    onDailyTimeSync = (e) => {
        this.setState(prevState => ({
            ...prevState,
            timeCards_sync: {
                ...prevState.timeCards_sync,
                dailyTimeSync: e
            }
        }))
    }

    onSetTimeEntryFormat = (e) => {
        console.log(e);
        this.setState(prevState => ({
            ...prevState,
            timeCards_sync: {
                ...prevState.timeCards_sync,
                time_entry: e
            }
        }))
    }

    onSendSettings = async () => {
        try {




            this.setState(prevState => ({
                ...prevState,
                onSyncingStatus: true
            }))
            await setProcProjectSettings({ procId: get(this.props, 'procProj.id'), ...this.state })
            this.setState(prevState => ({
                ...prevState,
                onSyncingStatus: false
            }))

            this.props.onCloseDialog('resourceManagement')
        } catch (error) {

        }
    }


    onDialog = (flag) => {

        this.setState(prevState => ({
            ...prevState,
            otherSettingsDialog: flag
        }))

    }

    render() {
        return (
            <div>
                <div className='d-flex daily-log-settings justify-content-center'>
                    <CardBox title='Timecard Sync'
                        logoSrc='https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/integrations/TimecardSyncIcon.svg' containerStyle={{ width: '400px' }}>

                        <div style={{ display: 'flex', alignItems: 'start', flexDirection: 'column' }}>

                            <div className='mt-3 daily-log-pm d-flex justify-content-center align-items-center'>

                                <TooltipInfo tooltipInfo='Automatically push Timesheet records from SmartBarrel to PROCORE every 5 minutes.' labelText='Auto Push Timesheet' />
                                <div style={{ marginLeft: '15px' }}>
                                    <Switch
                                        color='primary'
                                        checked={this.state.timeCards_sync.dailyTimeSync}
                                        onChange={(e) => this.onDailyTimeSync(e.target.checked)}
                                        value="dailyTimeSync" />
                                </div>
                            </div>


                            <div className='mt-3 daily-log-pm d-flex justify-content-center align-items-center'>
                                <TooltipInfo tooltipInfo="Include your subcontractor workers into your company's timesheet in PROCORE" labelText='Include Subcontractor Workers' />
                                <div style={{ marginLeft: '15px' }}>
                                    <Switch
                                        color='primary'
                                        checked={this.state.timeCards_sync.syncSubContractorWorkers}
                                        onChange={(e) => this.onSyncSubContractorWorkers(e.target.checked)}
                                        value="SyncSubcontractorWorkers" />
                                </div>
                            </div>

                            <div style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>

                                <TooltipInfo tooltipInfo='Choose the entry type format for your timesheet in PROCORE' labelText='Time Entry Type' />
                                <div style={{ marginLeft: '30px' }}>
                                    <Select
                                        labelId="time-entry"
                                        className='time-entry'
                                        id="time-entry"
                                        style={{ width: '200px' }}
                                        value={this.state.timeCards_sync.time_entry}
                                        onChange={(e) => this.onSetTimeEntryFormat(e.target.value)}>

                                        <MenuItem key="startTime_stopTime" value="startTime_stopTime">Start Time & Stop Time</MenuItem>
                                        <MenuItem key="total_hours" value="total_hours">Total Hours</MenuItem>

                                    </Select>
                                </div>
                            </div>

                            <div className='mt-3 daily-log-pm d-flex justify-content-center align-items-center'>
                                <TooltipInfo tooltipInfo='Only sync approved shifts from SmartBarrel to PROCORE' labelText='Only Approved Shift' />
                                <div style={{ marginLeft: '15px' }}>
                                    <Switch
                                        color='primary'
                                        checked={this.getFilterValue('approved')}

                                        onChange={(e) => this.handleFilterChange(e, 'approved')}
                                        value="Approved" />
                                </div>
                            </div>

                        </div>

                    </CardBox >

                    <CardBox title='CostCode Sync'
                        logoSrc='https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/integrations/CostCodeIcon.svg' containerStyle={{ width: '400px' }}>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <TooltipInfo tooltipInfo='Push your cost code from SmartBarrel to PROCORE' labelText='Export from SmartBarrel to PROCORE' />
                            <div style={{ marginLeft: '15px' }}>
                                <Switch
                                    color='primary'
                                    checked={this.state.costCodeSettings.costCode_sync}
                                    onChange={e => this.handCostCodeSwitchChange(e, 'costCode_sync')}
                                    value="CostCodeSync" />
                            </div>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <TooltipInfo tooltipInfo='Import your cost code from Procore to SmartBarrel' labelText='Import from PROCORE to SmartBarrel' />
                            <div style={{ marginLeft: '15px' }}>
                                <Switch
                                    color='primary'
                                    checked={this.state.costCodeSettings.import_costCode_sync}
                                    onChange={e => this.handCostCodeSwitchChange(e, 'import_costCode_sync')}
                                    value="ImportCostCodeSync" />
                            </div>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <TooltipInfo tooltipInfo='Sync Budgeted Costcodes with default timesheet cost codesw type' labelText='Sync Budgeted Cost Codes Only' />
                            <div style={{ marginLeft: '15px' }}>
                                <Switch
                                    color='primary'
                                    checked={this.state.costCodeSettings.budgeted_costCode_sync}
                                    onChange={e => this.handCostCodeSwitchChange(e, 'budgeted_costCode_sync')}
                                    value="BudgetedCostCodeSync" />
                            </div>
                        </div>
                    </CardBox>
                </div >
                <div className='d-flex justify-content-end'>
                    <Button
                        onClick={() => this.onSendSettings()}
                        variant="contained"
                        disabled={this.state.onSyncingStatus}
                        style={{ width: "85px" }}
                        className={`jr-btn bg-deep-orange  text-white`}>
                        {this.state.onSyncingStatus ? <CircularProgress color="white" style={{ marginLeft: '10px' }} size={15} /> : "Apply"}
                    </Button>
                    <Button
                        onClick={() => this.props.onCloseDialog('resourceManagement')}
                        variant="contained"
                        disabled={this.state.onSyncingStatus}
                        style={{ width: "85px" }}
                        className={`jr-btn bg-gray  text-black`}>
                        Close
                    </Button>
                </div>
            </div>
        )
    }
}