import React, { useState, useContext, useEffect } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { get } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import Select from 'react-select';
import LaunchIcon from '@material-ui/icons/Launch';
import { DatePickerComponent, Widget } from '../../../../components';
import { SettingsContext } from '../../../../contexts/SettingsContext';
import { getProjectFilter, getTimezones, procoreLogs } from '../../../../services/integrationService';
import { isNull, isArray } from 'lodash';
import { notificationCenter } from '../../../../services/appService';
import { size } from 'lodash';


export default function ProcoreLogs(props) {

  const logsType = [
    { value: null, label: 'All' },
    { value: 'procoreSetUp', label: 'Set up Integration or Refresh Token' },
    { value: 'procoreProjImport', label: 'Import Project' },
    { value: 'dashboardTimesheet', label: 'Dashboard Timesheet Sync' },
    { value: 'autoSyncTimesheet', label: 'Auto Timesheet Sync' },
    { value: 'manualSyncTimesheet', label: 'Manual Timesheet Sync' },
    { value: 'dailyLogSync', label: 'Manpower Sync' },
    { value: 'dailyConstructionLogSync', label: 'Daily Construction Sync' },
    { value: 'procoreProjectSettings', label: 'Project Settings' },
    { value: 'procoreSyncVendor', label: 'Project Sync Vendors' },
    { value: 'syncProjectList', label: 'Update Project List' },
  ]


  const selectStyles = {
    container: (provided, state) => ({
      ...provided,
      width: '200px',
      marginTop: '7px',
      marginLeft: '5px'
    })
  }

  const settingsContext = useContext(SettingsContext);

  const [state, setState] = useState({
    logs: [],
    procoreLogo: 'https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/integrations-logos/procore-logo.png',
    page: 1,
    timezone: [],
    projects: [],
    filters: {
      date: new Date(),
      logType: null,
      project: null,
      timezone: {
        "value": "America/New_York",
        "label": "America/New_York"
      }
    },
    logInfo: {
      status: false,
      info: null
    }
  });

  const toggleSpinner = (spinnerFlag = true, text = 'Loading...') => {
    settingsContext.onChangeLoader(spinnerFlag, text);
  };

  const gettingTimezone = async () => {

    try {
      const res = await getTimezones();
      setState(prevState => ({
        ...prevState,
        timezone: get(res, 'data')
      }))
    } catch (error) {

    }

  }

  const gettingProjectFilter = async () => {

    try {
      const res = await getProjectFilter();

      console.log(res)
      setState(prevState => ({
        ...prevState,
        projects: get(res, 'data')
      }))
    } catch (error) {

    }

  }

  const onError = e => {
    toggleSpinner(false);
    settingsContext.onRequestErrorMessage(e.response);
  };


  const gettingLogs = async (onFiltering = false, firstCall = false) => {
    try {


      if (firstCall)
        toggleSpinner(true, 'Getting Logs')


      const res = await procoreLogs({ page: onFiltering ? 1 : state.page, filters: state.filters });


      if (size(get(res, 'data', [])) === 0) {
        notificationCenter('warning', 'No data available')
      }

      setState(prevState => ({
        ...prevState,
        logs: onFiltering ? get(res, 'data', []) : [...prevState.logs, ...get(res, 'data', [])],
        page: onFiltering ? 1 : prevState.page + 1
      }));

      if (firstCall)
        toggleSpinner(false)
      // toggleSpinner(false)

    } catch (error) {
      onError(error)
    }
  }

  const handleDateChange = date => {
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        date
      }
    }))
  }


  const handleChangeType = e => {
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        logType: e
      }
    }))
  }

  const filteringLogs = () => {
    gettingLogs(true)
  }

  useEffect(() => {
    settingsContext.onChangeMenu('procore', 1);
    gettingLogs(false, true);
    gettingTimezone();
    gettingProjectFilter();
  }, []);

  const onShowLogInfo = (log) => {
    if (!isNull(get(log, 'logInfo', null))) {
      setState(prevState => ({
        ...prevState,
        logInfo: {
          status: true,
          info: JSON.stringify(get(log, 'logInfo'), null, 2)
        }
      }))
    }
  }


  const handleClose = () => {
    setState(prevState => ({
      ...prevState,
      logInfo: {
        status: false,
        info: null
      }
    }))
  }

  const handleChangeProject = e => {
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        project: e
      }
    }))
  }

  const handleChangeTimezone = (e) => {
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        timezone: e
      }
    }))
  }

  return (
    <div>
      <Widget title=' ' logoWidth='150' logo={state.procoreLogo} styleName="jr-card-profile-sm widget-procore-projects" showIntegrationAdmin={get(JSON.parse(localStorage.getItem('userInfo')), 'integrations.procore')}>
        <div className='project-details d-flex align-items-center'>

          <div style={{ display: 'flex', alignItems: 'center', }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DatePickerComponent
                currentDate={state.filters.date}
                disabled={false}
                handleDateChange={handleDateChange}
                inputProps={{ style: { textAlign: 'center', padding: '9.5px 0px', fontSize: '15px', width: '120px' } }}
                style={{ width: 80 }}
              />

              <Select
                value={state.filters.logType}
                onChange={handleChangeType}
                options={logsType}
                isSearchable={true}
                styles={selectStyles}
                placeholder='Select Type....'

              />


              <Select
                value={state.filters.project}
                onChange={handleChangeProject}
                options={state.projects}
                isSearchable={true}
                placeholder='Select Project....'
                styles={selectStyles}

              />

              <Select
                value={state.filters.timezone}
                onChange={handleChangeTimezone}
                options={state.timezone}
                isSearchable={true}
                placeholder='Select Timezone....'
                styles={selectStyles}

              />

            </div>
            <div>
              <Button onClick={filteringLogs} variant="contained" color="primary" style={{ textTransform: 'capitalize', marginTop: '5px', marginLeft: '12px' }}>
                Filter
              </Button>
            </div>

          </div>
        </div>
      </Widget>
      <div
        style={{
          height: 500,
          overflow: 'auto'
        }}
      >
        <InfiniteScroll
          dataLength={state.logs.length}
          next={gettingLogs}
          height={500}
          hasMore={true}
          scrollableTarget="scrollableDiv"
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">User Name</TableCell>
                <TableCell align="left">Company</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Date</TableCell>
                <TableCell align="left">Log Info</TableCell>
                <TableCell align="left">Description</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>

              {state.logs.map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell component="th" scope="row">
                    {get(row, 'userName')}
                  </TableCell>
                  <TableCell align="left">{get(row, 'companyName')}</TableCell>
                  <TableCell align="left">{get(row, 'type')}</TableCell>
                  <TableCell align="left">{get(row, 'created_at')}</TableCell>
                  <TableCell align="left">
                    <LaunchIcon onClick={() => onShowLogInfo(row)} style={{ fontSize: '20px', marginLeft: '13px', cursor: `${isNull(get(row, 'logInfo', null)) ? 'not-allowed' : 'pointer'}` }} />
                  </TableCell>
                  <TableCell align="left">{get(row, 'description')}</TableCell>
                </TableRow>
              ))}


            </TableBody>



          </Table>
        </InfiniteScroll>
      </div>

      <Dialog
        open={state.logInfo.status}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Log Information"}</DialogTitle>
        <DialogContent>
          {isArray(state.logInfo.info) ? state.logInfo.info.map(item => (<pre>{item}</pre>)) : <pre>{state.logInfo.info}</pre>}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>


      {/* <Pagination style={{ float: 'right' }} className='mt-4' size="small" onChange={onPaginationChange} /> */}


    </div >

  )
};