import React, { useState, createContext, useEffect } from 'react';
import { notificationCenter } from '../services/appService';
import { get, has, set, isEmpty } from 'lodash';
import { NotificationManager } from 'react-notifications';


export const SettingsContext = createContext();

export default function SettingsContextProvider(props) {

  const [state, setState] = useState({
    loader: {
      status: false,
      msg: 'loading'
    },
    error: {
      status: '',
      msg: ''
    },
    integrations: [],
    copyIntegrations: [],
    menus: {
      procore: [
        {
          idx: 0,
          type: 'Projects',
          url: '/procore-projects',
          active: true,
          icon: 'zmdi zmdi-city-alt text-orange'
        },
        {
          idx: 1,
          type: 'Workers',
          url: '/procore-import-workers',
          active: false,
          icon: 'zmdi zmdi-format-list-bulleted text-orange'
        },
        {
          idx: 1,
          type: 'Classifications',
          url: '/procore-import-classifications',
          active: false,
          icon: 'zmdi zmdi-format-list-bulleted text-orange'
        },
        {
          idx: 1,
          type: 'Logs',
          url: '/procore-logs',
          active: false,
          icon: 'zmdi zmdi-format-list-bulleted text-orange'
        }
      ],
      cmic: [
        {
          idx: 0,
          type: 'Projects',
          url: '/cmic-projects',
          active: true,
          icon: 'zmdi zmdi-city-alt text-orange'
        },
        {
          idx: 1,
          type: 'Workers',
          url: '/cmic-import-workers',
          active: false,
          icon: 'zmdi zmdi-format-list-bulleted text-orange'
        },
        {
          idx: 1,
          type: 'Activity Logs',
          url: '/cmic-activity-logs',
          active: false,
          icon: 'zmdi zmdi-format-list-bulleted text-orange'
        }
      ],
      main: [
        {
          idx: 0,
          type: 'All',
          url: '',
          active: true,
          icon: 'zmdi zmdi-balance text-orange'
        },
        {
          idx: 1,
          type: 'Payroll',
          url: '',
          active: false,
          icon: 'zmdi zmdi-balance text-orange'

        },
        {
          idx: 2,
          type: "Construction",
          url: '',
          active: false,
          icon: 'zmdi zmdi-balance text-orange'
        }
      ]
    },
    currentMenu: [
      {
        idx: 0,
        type: 'All',
        url: '',
        active: true,
        icon: 'zmdi zmdi-balance text-orange'
      },
      {
        idx: 1,
        type: 'Payroll',
        url: '',
        active: false,
        icon: 'zmdi zmdi-balance text-orange'
      },
      {
        idx: 2,
        type: "Construction",
        url: '',
        active: false,
        icon: 'zmdi zmdi-balance text-orange'
      }
    ],
    integrationSettings: {
      cmic: {
        counter: 0,
        total: 0,
        processed: 0,
        unProcessed: 0
      }
    }
  })

  const onChangeLoader = (status, msg = 'loading') => {
    setState(prevState => ({
      ...prevState,
      loader: {
        status,
        msg
      }
    }))
  }

  const onRequestErrorMessage = (response) => {

    console.log("Error response ", response)


    if (get(response, 'status') === 302) {
      window.location.href = get(response, 'data.location')
    } else {
      onChangeLoader(false)
      NotificationManager.error(`${get(response, 'status', 500)} - ${get(response, 'data.error', 'Contact Us!')}`);
    }
  }

  const handleMainMenuLeft = (menu) => {

    if (!isEmpty(get(menu, 'url'))) {
      window.location.href = get(menu, 'url')
    } else {

      let aux = state.currentMenu.map(integration => {
        set(integration, 'active', get(integration, 'idx') === get(menu, 'idx'));
        return integration
      });

      setState(prevState => ({
        ...prevState,
        integrations: get(menu, 'idx') === 0 ? prevState.copyIntegrations : prevState.copyIntegrations.filter(item => get(item, 'type') === get(menu, 'type')),
        menus: {
          ...prevState.menus,
          integrationsMain: aux
        }
      }));

    }


  }

  const onNotificationError = (key, message = '') => {
    switch (key) {
      case 'info':
        NotificationManager.info(message);
        break;
      case 'success':
        NotificationManager.success(message);
        break;
      case 'warning':
        NotificationManager.warning(message);
        break;
      case 'error':
        NotificationManager.error(message);
        break;
    }
  }

  const onSetIntegrations = data => {

    setState(prevState => ({
      ...prevState,
      integrations: data,
      copyIntegrations: data
    }))

  }

  const onChangeCMICCounter = () => {

    setState(prevState => ({
      ...prevState,
      integrationSettings: {
        ...prevState.integrationSettings,
        cmic: {
          ...prevState.integrationSettings.cmic,
          counter: prevState.integrationSettings.cmic.counter + 1
        }
      }
    }))

  }

  const onChangeCMICTotal = (data) => {

    setState(prevState => ({
      ...prevState,
      integrationSettings: {
        ...prevState.integrationSettings,
        cmic: {
          ...prevState.integrationSettings.cmic,
          total: data
        }
      }
    }))
  }

  const onChangeCMICProcessed = (type) => {

    setState(prevState => ({
      ...prevState,
      integrationSettings: {
        ...prevState.integrationSettings,
        cmic: {
          ...prevState.integrationSettings.cmic,
          [type]: prevState.integrationSettings.cmic[type] + 1
        }
      }
    }))

  }

  const onChangeMenu = (key = "main", idx = 0) => {
    let aux = get(state.menus, key, []).map(menu => {
      set(menu, 'active', get(menu, 'idx') === idx);
      return menu
    })
    setState(prevState => ({
      ...prevState,
      currentMenu: aux
    }))
  }

  return (
    <SettingsContext.Provider
      value={{
        ...state,
        onChangeLoader,
        onRequestErrorMessage,
        onNotificationError,
        onSetIntegrations,
        handleMainMenuLeft,
        onChangeMenu,
        onChangeCMICTotal,
        onChangeCMICCounter,
        onChangeCMICProcessed
      }}>
      {props.children}
    </SettingsContext.Provider>
  )
};
