import React, { useState } from 'react';
import { Badge, Button, FormControl, Input, InputAdornment, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { notValid } from '../../../../services/appService';
import { get, isNull } from "lodash";
import { StatusField, TooltipInfo } from '../../../../components';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ApartmentIcon from '@material-ui/icons/Apartment';
export default function CMICTableBody({ projects, projectImport, onOpenSubJobs, onPerDiemDialog }) {


  return (
    projects.map(proj => (
      <TableRow key={proj.id}>
        <TableCell component="th" scope="row">
          {get(proj, 'id', '-')}
        </TableCell>
        <TableCell>
          {!notValid(get(proj, 'name')) ? get(proj, 'name') : <StatusField />}
        </TableCell>
        <TableCell>
          {!notValid(get(proj, 'address')) ? get(proj, 'address') : <StatusField />}
        </TableCell>
        <TableCell>
          {!notValid(get(proj, 'city')) ? get(proj, 'city') : <StatusField />}
        </TableCell>
        <TableCell>{!notValid(get(proj, 'state_code')) ? get(proj, 'state_code') :
          <StatusField />}
        </TableCell>

        <TableCell align='center'>{isNull(get(proj, 'last_sync')) ?
          <Badge color="danger"> Never</Badge> :
          <Badge color="success">{get(proj, 'last_sync')}</Badge>}
        </TableCell>
        <TableCell align='center'>
          <div >
            {
              get(proj, 'isImported') ?
                <Button
                  variant="contained"
                  disabled={true}
                  style={{ width: "85px" }}
                  className={`jr-btn ${!get(proj, 'isImported') ? 'bg-teal' : 'bg-gray'}  text-white`}>
                  <i className="zmdi zmdi-download" />
                  <span>Imported</span>
                </Button> :
                <Button onClick={() => projectImport(proj)}
                  variant="contained"
                  style={{ width: "85px" }}
                  className={`jr-btn ${!get(proj, 'isImported') ? 'bg-deep-orange' : 'bg-gray'}  text-white`}>
                  <i className="zmdi zmdi-download" />
                  <span>Import</span>
                </Button>
            }

            {get(proj, 'isImported') &&
              <>
                <Tooltip title='Import SubJobs' placement="top">
                  <AccountTreeIcon style={{ cursor: 'pointer' }} onClick={() => onOpenSubJobs(get(proj, 'id', '-'))} />
                </Tooltip>
              </>
            }
          </div>
        </TableCell>
        <TableCell>
          <ApartmentIcon onClick={() => get(proj, 'enablePerDiem', false) && onPerDiemDialog(true, proj)} style={{ marginLeft: '15px', cursor: `${get(proj, 'enablePerDiem', false) ? 'pointer' : 'not-allowed'}` }} />
        </TableCell>
      </TableRow>
    ))
  );
};