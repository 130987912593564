import React from 'react';
import LuxonUtils from '@date-io/luxon'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { IconButton, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({

  root: {
    maxWidth: "300px !important"
  }
}))

export default function DatePickerComponent(props) {

  const { currentDate, inputProps, handleDateChange, formatDate = "dd LLL y" } = props;

  const classes = useStyles();


  return (
    <div>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <DatePicker
          inputVariant="outlined"
          disableFuture={true}
          inputProps={inputProps}
          value={currentDate}
          onChange={handleDateChange}
          format={formatDate}
          autoOk
          showTodayButton
          margin="normal"
          classes={{ paper: classes.paper }}

        />
      </MuiPickersUtilsProvider>
    </div>
  )

}
