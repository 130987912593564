import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import React from 'react';

export default function CMICSetup(props) {

  const { open, onChangeCMICInitialData, handleCloseCMICSettings, addCMICIntegration } = props;
  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <img width='50px' height='auto' className="mb-3" src="https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/integrations-logos/cmicLogo.png" alt="Integration Logo" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please add your CMIC credentials
        </DialogContentText>
        <TextField
          required
          onChange={e => onChangeCMICInitialData('url', e.target.value)}
          id="url"
          label="url"
          type="text"
          fullWidth
        />
        <TextField
          required
          onChange={e => onChangeCMICInitialData('username', e.target.value)}
          id="username"
          label="username"
          type="text"
          fullWidth
        />
        <TextField
          required
          onChange={e => onChangeCMICInitialData('password', e.target.value)}
          id="Password"
          label="password"
          type="password"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleCloseCMICSettings}>
          Cancel
        </Button>
        <Button color="primary" onClick={addCMICIntegration}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  )
}
