import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import Checkbox from '@material-ui/core/Checkbox';

import { Button, CircularProgress } from '@material-ui/core';
import { get, isNull, pick, each } from 'lodash'
import { syncCMICSubJobs } from '../../../../services/cmicService';
import { notificationCenter } from '../../../../services/appService';



const EnhancedTableHead = (props) => {
  const { onSelectAllClick, numSelected, rowCount, syncing } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            disabled={syncing}
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        <TableCell>
          SubJob Name
        </TableCell>
        <TableCell>
          Number
        </TableCell>
        <TableCell>
          Synced Since
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "#ff5722",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.text.primary,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, onUpdate, updatingVendors } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
        [classes.notSelected]: numSelected === 0,
      })}
    >

      <Typography style={{ color: numSelected === 0 ? '#f15922' : 'white' }} variant="subtitle1" component="div">
        {numSelected} selected
      </Typography>


    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function CMICSubJobs(props) {

  const classes = useStyles();

  const { subs, onCloseSubJobs, projectNumber } = props;
  const [selected, setSelected] = React.useState([]);
  const [syncing, setSyncing] = React.useState(false);


  useEffect(() => {
    const aux = subs.filter(item => item.synced);
    setSelected(aux.map(item => get(item, 'number')))
  }, [])


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = subs.map((n) => n.number);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    console.log(name)
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    console.log(newSelected)

    setSelected(newSelected);
  };


  const isSelected = (row) => selected.indexOf(get(row, 'number')) !== -1;


  const syncingSubs = async () => {
    try {
      setSyncing(true)

      const res = await syncCMICSubJobs(selected, projectNumber);
      setSyncing(false);
      onCloseSubJobs()
    } catch (error) {
      notificationCenter('error', 'Error Syncing SubJobs')
    }


  }

  return (
    <div className={classes.root}>
      <EnhancedTableToolbar numSelected={selected.length} />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            syncing={syncing}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={subs.length}
          />
          <TableBody>
            {subs.map((row, index) => {
              const isItemSelected = isSelected(row);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.number}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color='primary'
                      checked={isItemSelected}
                      disabled={syncing}
                      onClick={(event) => handleClick(event, row.number)}
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </TableCell>
                  <TableCell component="th" id={labelId} scope="row" padding="default">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" id={labelId} scope="row" padding="default">
                    {row.number}
                  </TableCell>
                  <TableCell component="th" id={labelId} scope="row" padding="default">
                    {isNull(row.created_at) ? 'Never' : row.created_at}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ float: 'right', marginTop: '20px' }}>
        <Button color="primary" disabled={syncing} onClick={() => onCloseSubJobs()}>
          Cancel
        </Button>
        <Button onClick={() => syncingSubs()} disabled={syncing} color="primary">
          {syncing ? <CircularProgress style={{ cursor: 'auto' }} size={15} color="primary" /> : "Save"}
        </Button>
      </div>
    </div>
  );
}
