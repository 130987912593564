import React from 'react';
import PropTypes from "prop-types";
import "../../static/styles/app.scss"
import { get } from 'lodash';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import "antd/dist/antd.css";


// Panels
import {
    HeaderSB,
    Footer,
    LeftMenu
} from '../../components/index';

class LayoutSB extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            searchBox: false,
            searchText: '',
            hideLeftMenu: false
        };
    }


    onMenu = () => {
        this.setState(prevState => ({
            ...prevState,
            hideLeftMenu: !prevState.hideLeftMenu
        }));
    };

    render() {
        return (
            <div className="app-container" >
                <LeftMenu
                    globalState={get(this.props.children, 'props.globalState', null)}
                    onMenu={this.onMenu}
                    hideLeftMenu={this.state.hideLeftMenu} />
                <div className="app-main-container">
                    <div className="app-header app-header-horizontal">
                        <HeaderSB onMenu={this.onMenu} />
                    </div>
                    <main className="app-main-content-wrapper">

                        <div className="app-main-content">
                            {this.props.children}
                        </div>

                        <Footer />
                    </main>
                </div>
                <NotificationContainer />
            </div>
        );
    }

}

LayoutSB.propTypes = {
    children: PropTypes.element.isRequired
};

export default LayoutSB;