import { NotificationManager } from 'react-notifications';
import { get, isEmpty, isNull, isUndefined } from "lodash";

import axios from 'axios';


const baseUrl = get(process.env, 'REACT_APP_BASE_URL', 'https://integrations.connectedbarrelapi.com');
const projectDashboardUrl = get(process.env, 'REACT_APP_PROJECT_DASHBOARD_API', 'https://integrations.connectedbarrelapi.com');


const setHeaders = () => {
    return {
        'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
        'Accept': 'application/json',
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': "*"
    };
};

export const userProfile = () => {

    return axios.get(`${baseUrl}/user-profile`, { headers: setHeaders() })
};


export const getRevokeWorkersURL = (integrationKey) => {
    return axios.get(`${projectDashboardUrl}/revoke-integrations-workers/${integrationKey}`, { headers: setHeaders() })
}

export const leftMenuOptions = () => {
    return [
        {
            idx: 0,
            key: 'construction',
            title: 'Construction',
            icon: 'zmdi zmdi-balance'
        },
        {
            idx: 1,
            key: 'payroll',
            title: 'Payroll',
            icon: 'zmdi zmdi-balance'
        }
    ];
};


export const getUserInfo = () => {
    return JSON.parse(localStorage.getItem('userInfo'))
}

export const notificationCenter = (type, message) => {
    switch (type) {
        case 'info':
            NotificationManager.info(message);
            break;
        case 'success':
            NotificationManager.success(message);
            break;
        case 'warning':
            NotificationManager.warning(message);
            break;
        case 'error':
            NotificationManager.error(message);
            break;
    }
};

export const notValid = value => {
    return isUndefined(value) || isEmpty(value) || isNull(value);
};


