import React from "react";
import { get, split, first } from 'lodash';
import {
    setupAdpService
} from '../../../../services/adpServices';
import {
    notificationCenter
} from '../../../../services/appService';


class AdpSetup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            procoreSyncData: [],
            dialogConfirmationStatus: false
        };
    }

    toggleSpinner = (spinnerFlag = true) => {
        this.props.globalState.onSpinner(spinnerFlag);
    };

    onError = e => {
        this.props.globalState.onError(e);
    };

    componentDidMount() {

        this.toggleSpinner(true);

        setupAdpService().then(res => {

            console.log("Data ", res.data)

            if (get(res, 'data.dataConsent', true)) {
                window.location.href = '/adp-settings';
            } else {
                window.location.href = get(res, 'data.url');
            }

        }).catch(e => {
            this.toggleSpinner(false);
            notificationCenter('error', get(e, 'response.data.error'))
            setTimeout(() => {
                window.location.href = '/home';
            }, 2000)
        })
    }

    render() {
        return <p></p>;
    };
}

export default AdpSetup;