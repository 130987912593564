import React from 'react';
import Close from '@material-ui/icons/Close';
import { InputBase, CircularProgress, InputAdornment, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";


export const styles = makeStyles(theme => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: "#a8afba",
        marginLeft: 0,
        width: "auto",
        height: "40px"
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 3),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
                width: 200,
            },
        },
    },
}));

const SearchBox = ({ placeholder, onChange, onClearSearch, value }) => {

    const classes = styles();

    return (

        <div className={classes.search}>
            {/* <div className={classes.searchIcon} >
                { searching ? <CircularProgress color='inherit' size={24} /> :  <SearchIcon />}
            </div> */}
            <InputBase
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e)}
                classes={{
                    input: classes.inputInput
                }}
                inputProps={{ 'aria-label': 'search' }}
                endAdornment={
                    <InputAdornment position="end">
                        <Close style={{ marginRight: '10px', fontSize: '15px', cursor: 'pointer' }} onClick={onClearSearch} />
                    </InputAdornment>
                }
            />
        </div>
    )
};

SearchBox.propTypes = {
    placeholder: PropTypes.string
};

SearchBox.defaultProps = {
    placeholder: 'Search...'
};
export default SearchBox;

