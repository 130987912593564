import React from 'react';
import PropTypes from "prop-types";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
    paper: {
        width: "100% !important"
    }
}));


const DialogConfirmation = (props) => {
    const classes = useStyles();

    return (
        <Dialog
            open={props.open}
            className={classes.paper}
            TransitionComponent={Transition}
            disableBackdropClick={props.disableBackdropClick}
            keepMounted
            maxWidth={props.maxWidth}
            onClose={() => props.onHandleClose(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{props.title}</DialogTitle>
            <DialogContent>
                {props.dialogContent}
            </DialogContent>

            <DialogActions>
                {props.showActions && <div>
                    <Button onClick={() => props.onHandleClose(false)} color="secondary">
                        {props.disagreeText}
                    </Button>
                    <Button onClick={() => props.onHandleClose(true)} color="primary">
                        {props.agreeText}
                    </Button>
                </div>}
                {props.doneAction &&
                    <Button onClick={() => props.onHandleClose(true)}>
                        Done
                    </Button>
                }

            </DialogActions>

        </Dialog>
    );
};

DialogConfirmation.propTypes = {
    onHandleClose: PropTypes.func,
    disagreeText: PropTypes.string,
    agreeText: PropTypes.string,
    title: PropTypes.string,
    dialogContent: PropTypes.element,
    disableBackdropClick: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    showActions: PropTypes.bool,
    doneAction: PropTypes.bool,
    maxWidth: PropTypes.string,
};

DialogConfirmation.defaultProps = {
    disagreeText: "Disagree",
    agreeText: "Agree",
    title: "Are you sure?",
    dialogContent: "",
    disableBackdropClick: false,
    showActions: true,
    doneAction: false,
    maxWidth: 'md'
};


export default DialogConfirmation;