const procoreProjectsHeaders = {
    headers: [
        {
            title: 'Project #',
            align: 'left',
            status: true
        },
        {
            title: 'Name',
            align: 'left',
            status: true
        },
        {
            title: 'Address',
            align: 'left',
            status: true
        },
        {
            title: 'City',
            align: 'left',
            status: true
        },
        {
            title: 'State',
            align: 'left',
            status: true
        },

        {
            title: 'ZipCode',
            align: 'center',
            status: true
        },

        {
            title: 'Imported',
            align: 'center',
            status: true
        },
        {
            title: 'Daily Logs',
            align: 'center',
            status: true
        },
        {
            title: 'Workforce Management',
            align: 'center',
            status: true
        },

        {
            title: 'Actions',
            align: 'center',
            status: true
        }
    ]
};


const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumber;
}

export {
    procoreProjectsHeaders,
    formatPhoneNumber
}