import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, CardTitle } from "reactstrap";
import { get, isNull } from "lodash";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { Avatar, Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { formatPhoneNumber } from "../../constants/helpers";


const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

const LogoRender = ({ logoWidth, logo, showIntegrationAdmin }) => {

    const classes = useStyles();


    // const [state, setState] = useState({
    //     userInfo: false
    // });
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleUserInfo = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <div>
            <img width={logoWidth} src={logo} alt="logo" />
            {!isNull(showIntegrationAdmin) ?
                <AssignmentIndIcon aria-describedby={id} onClick={handleUserInfo} style={{ marginTop: '4px', marginLeft: '10px', fontSize: '20px', cursor: 'pointer' }} />
                : null}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <b >Integration Admin</b>
                    <div style={{ marginTop: '10px', display: 'flex' }}>
                        <div>
                            <Avatar
                                alt='...'
                                src={get(showIntegrationAdmin, 'profile', null)}
                                className={classes.large}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '16px' }}>
                            <b>{get(showIntegrationAdmin, 'fullName')}</b>
                            <span style={{ color: "#a8afba" }}>{formatPhoneNumber(get(showIntegrationAdmin, 'phone'))}</span>
                            <span style={{ color: "#a8afba" }}>{get(showIntegrationAdmin, 'email')}</span>
                        </div>
                    </div>
                </div>
            </Popover>
        </div>
    )

}

const Widget = ({ children, styleName, title, icon, logo, logoWidth, cardTitleStyle, showIntegrationAdmin = null }) => {

    return (
        <Card className={`jr-card jr-card-widget ${styleName}`}>
            {title ? <CardTitle className={cardTitleStyle} >
                {logo ? <LogoRender logoWidth={logoWidth} logo={logo} showIntegrationAdmin={showIntegrationAdmin} /> : null} {icon ? <i className={`fa ${icon} m`} /> : null} {title}
            </CardTitle> : null}
            {children}
        </Card>
    )
};

export default Widget;


Widget.defaultProps = {
    styleName: '',
    logoWidth: '200'
};

Widget.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    logoWidth: PropTypes.string
};
