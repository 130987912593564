import React from 'react';

export default function ArrowPagination({ page, onPaginationChange }) {

    return (
        <div className='adp-pagination d-flex justify-content-end mr-4 mt-4'>
            <div onClick={() => onPaginationChange('left', page)} className="left">
                <i style={{
                    'fontSize': '30px',
                    'cursor': `${page > 1 ? 'pointer' : 'no-drop'}`,
                    'color': `${page > 1 ? 'black' : '#D3D3D3'}`
                }} className="zmdi zmdi-chevron-left mr-3" />
            </div>
            <div className="right" onClick={() => onPaginationChange('right', page)}>
                <i style={{
                    'fontSize': '30px',
                    'cursor': 'Pointer'
                }} className="zmdi zmdi-chevron-right" />
            </div>
        </div>
    );


}
