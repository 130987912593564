import React from 'react';
import {get} from "lodash";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

class WeatherLogSync extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            weather: get(props , 'proj.weather_sync.status' , false)
        };

    }


    handleChange = e => {

        this.setState({weather: e} , () => {
            this.props.onWeatherSync(this.props.proj, this.state.weather);
        });
    };

    render() {
        return (
            <FormControlLabel
                disabled={!get(this.props.proj, 'isImported')}
                control={
                    <Switch
                        checked={this.state.weather}
                        onChange={e => this.handleChange(e.target.checked)}
                        value={this.state.weather}
                        color="primary"
                    />
                }
                label="Weather Sync"
            />
        );
    }
}

export default WeatherLogSync;