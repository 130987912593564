import React, { Component } from 'react';

import {
    Switch,
    InputLabel,
    Select,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    Button,
    CircularProgress
} from '@material-ui/core';

import { getHoursType } from '../../../../constants/hours';
import { CardBox } from "../../../../components";

import { get, set, find } from 'lodash';

import '../../../../static/styles/components/_dailyLogSetting.scss'
import { setProcProjectSettings } from '../../../../services/integrationService';

export default class DailyLogSetting extends Component {

    constructor(props) {
        super(props);

        this.state = {
            onSyncingStatus: false,
            daily_sync: {
                AM: {
                    value: {
                        hour: get(this.props.procProj, 'daily_sync.AM.time.hour', ''),
                        minute: 0
                    },
                    status: get(this.props.procProj, 'daily_sync.AM.status', false),
                    hours: getHoursType('AM')
                },
                PM: {
                    value: {
                        hour: get(this.props.procProj, 'daily_sync.PM.time.hour', ''),
                        minute: 0
                    },
                    status: get(this.props.procProj, 'daily_sync.PM.status', false),
                    hours: getHoursType('PM')
                },
                syncSubContractorWorkers: get(this.props.procProj, 'daily_sync.syncSubContractorWorkers', false),
                filters: get(this.props.procProj, 'daily_sync.filters', [
                    {
                        name: 'approved',
                        value: false
                    }
                ]),
                staticShiftsHours: {
                    key: 'staticShiftHours',
                    status: get(this.props.procProj, 'daily_sync.staticShiftsHours.status', false),
                    value: get(this.props.procProj, 'daily_sync.staticShiftsHours.value', 8)
                },
                metadata: get(this.props.procProj, 'daily_sync.metadata', [
                    {
                        key: 'workStatement',
                        status: true
                    }
                ]),
                type: {
                    manpower: get(this.props.procProj, 'daily_sync.type.manpower', true),
                    dailyConstruction: get(this.props.procProj, 'daily_sync.type.dailyConstruction', false)
                }
            }
        }
    }

    handleDailyTypeChange = (event, name) => {

        const checked = event.target.checked;


        this.setState(prevState => ({
            ...prevState,
            daily_sync: {
                ...prevState.daily_sync,
                type: {
                    ...prevState.daily_sync.type,
                    [name]: checked
                }
            }
        }))
    }

    handleDailyChange = (event, name) => {

        const checked = event.target.checked;


        this.setState({
            ...this.state,
            daily_sync: {
                ...this.state.daily_sync,
                [name]: {
                    ...this.state.daily_sync[name],
                    status: checked
                }
            }
        });

    };

    handleFilterChange = (event, name) => {

        const checked = event.target.checked;

        const filters = this.state.daily_sync.filters.map(item => {
            if (get(item, 'name') === name) set(item, 'value', checked);

            return item;
        })
            ;

        this.setState(prevState => ({
            ...prevState,
            daily_sync: {
                ...prevState['daily_sync'],
                filters
            }
        }))
    };

    handleMetadataChange = (event, key) => {

        const checked = event.target.checked;

        const metadata = this.state.daily_sync.metadata.map(metadata => {
            if (get(metadata, 'key') === key) set(metadata, 'status', checked);

            return metadata;
        });

        console.log(metadata)

        this.setState(prevState => ({
            ...prevState,
            daily_sync: {
                ...prevState['daily_sync'],
                metadata
            }
        }))
    };

    handleStaticShiftHoursChange = (event, key) => {
        const temp = key === 'status' ? event.target.checked : event.target.value;

        this.setState(prevState => ({
            ...prevState,
            daily_sync: {
                ...prevState['daily_sync'],
                staticShiftsHours: {
                    ...prevState.daily_sync.staticShiftsHours,
                    [key]: temp
                }
            }
        }))
    }

    handleWeatherChange = (event) => {

        const checked = event.target.checked;

        this.setState({
            ...this.state,
            weatherLog: checked
        })
    };

    onTimeSelect = (e, logType) => {
        const value = e.target.value;

        this.setState({
            ...this.state,
            daily_sync: {
                ...this.state.daily_sync,
                [logType]: {
                    ...this.state.daily_sync[logType],
                    value: {
                        hour: value,
                        minute: 0
                    }
                }
            }
        });
    };

    getFilterValue = (key) => {
        const filter = find(this.state.daily_sync.filters, filter => get(filter, 'name') === key);
        return get(filter, 'value', false)
    };

    getMetadataValue = (key) => {
        const metadata = find(this.state.daily_sync.metadata, metadata => get(metadata, 'key') === key);
        return get(metadata, 'status', false)
    };


    onSyncSubContractorWorkers = (e) => {
        this.setState(prevState => ({
            ...prevState,
            daily_sync: {
                ...prevState.daily_sync,
                syncSubContractorWorkers: e
            }
        }))
    }


    onSendSettings = async () => {
        try {
            console.log(this.props)

            const daily_sync_payload = {
                AM: {
                    status: get(this.state, 'daily_sync.AM.status', false),
                    time: {
                        hour: get(this.state, 'daily_sync.AM.value.hour', null),
                        minute: 0
                    }
                },
                PM: {
                    status: get(this.state, 'daily_sync.PM.status', false),
                    time: {
                        hour: get(this.state, 'daily_sync.PM.value.hour', null),
                        minute: 0
                    }
                },
                syncSubContractorWorkers: get(this.state, 'daily_sync.syncSubContractorWorkers', false),
                staticShiftsHours: get(this.state, 'daily_sync.staticShiftsHours', []),
                filters: get(this.state, 'daily_sync.filters', []),
                metadata: get(this.state, 'daily_sync.metadata', []),
                type: get(this.state, 'daily_sync.type', null)

            };

            this.setState(prevState => ({
                ...prevState,
                onSyncingStatus: true
            }))
            await setProcProjectSettings({ procId: get(this.props, 'procProj.id'), daily_sync: daily_sync_payload })
            this.setState(prevState => ({
                ...prevState,
                onSyncingStatus: false
            }))

            this.props.onCloseDialog('dailyLogSettings')
        } catch (error) {

        }
    }


    render() {
        return (
            <div>
                <div className='d-flex daily-log-settings justify-content-center'>
                    <CardBox className='daily-log-settings-content' title='Daily Log' contentClassName='d-flex flex-column align-items-start'
                        logoSrc='https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/integrations/ManpowerLogIcon.svg' containerStyle={{ width: '350px' }}>
                        <div className='mb-2'>
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Checkbox color='primary' checked={this.state.daily_sync.type.manpower} onChange={(e) => this.handleDailyTypeChange(e, 'manpower')} name="checkedA" />}
                                    label="Manpower"
                                />
                                <FormControlLabel
                                    control={<Checkbox color='primary' checked={this.state.daily_sync.type.dailyConstruction} onChange={(e) => this.handleDailyTypeChange(e, 'dailyConstruction')} name="checkedA" />}
                                    label="Daily Construction Report"
                                />
                            </FormGroup>
                        </div>
                        <div className='daily-log-am d-flex justify-content-center  align-items-center'>
                            <b>AM</b>
                            <Switch
                                color='primary'
                                checked={this.state.daily_sync.AM.status}
                                onChange={(e) => this.handleDailyChange(e, 'AM')}
                                value="AM" />
                            <div className='select-daily-sync'>
                                <Select
                                    labelId="time-picker"
                                    disabled={!this.state.daily_sync.AM.status}
                                    className='time-picker'
                                    id="time-picker"
                                    value={this.state.daily_sync.AM.value.hour}
                                    onChange={(e) => this.onTimeSelect(e, 'AM')}>
                                    {this.state.daily_sync.AM.hours.map(hour => (
                                        <MenuItem key={hour.value} value={hour.value}>{hour.title}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>

                        <div className='mt-3 daily-log-pm d-flex justify-content-center  align-items-center'>
                            <b>PM</b>
                            <Switch
                                color='primary'
                                checked={this.state.daily_sync.PM.status}
                                onChange={(e) => this.handleDailyChange(e, 'PM')}
                                value="PM" />
                            <div className='select-daily-sync'>
                                <Select
                                    labelId="time-picker"
                                    disabled={!this.state.daily_sync.PM.status}
                                    className='time-picker'
                                    id="time-picker"
                                    value={this.state.daily_sync.PM.value.hour}
                                    onChange={(e) => this.onTimeSelect(e, 'PM')}>
                                    {this.state.daily_sync.PM.hours.map(hour => (
                                        <MenuItem key={hour.value} value={hour.value}>{hour.title}</MenuItem>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className='mt-3 daily-log-pm d-flex justify-content-center align-items-center'>
                            <b>Overwrite shift hours</b>
                            <Switch
                                color='primary'
                                checked={this.state.daily_sync.staticShiftsHours.status}
                                onChange={(e) => this.handleStaticShiftHoursChange(e, 'status')}
                                value="StaticShiftHours" />
                            <TextField value={this.state.daily_sync.staticShiftsHours.value} onChange={(e) => this.handleStaticShiftHoursChange(e, 'value')} disabled={!this.state.daily_sync.staticShiftsHours.status} style={{ width: '50px', marginBottom: '15px' }} id="standard-basic" label="Hours" type='number' step="0.01" />
                        </div>
                        <div className='mt-3 daily-log-pm d-flex justify-content-center align-items-center'>
                            <b>Only Approved Shift</b>
                            <Switch
                                color='primary'
                                checked={this.getFilterValue('approved')}
                                onChange={(e) => this.handleFilterChange(e, 'approved')}
                                value="Approved" />
                        </div>
                        <div className='mt-3 daily-log-pm d-flex justify-content-center align-items-center'>
                            <b>Bundle Non-vendor Headcount</b>
                            <Switch
                                color='primary'
                                checked={this.state.daily_sync.syncSubContractorWorkers}
                                onChange={(e) => this.onSyncSubContractorWorkers(e.target.checked)}
                                value="SyncSubcontractorWorkers" />
                        </div>
                        <div className='mt-3 daily-log-pm d-flex justify-content-center align-items-center'>
                            <b>Sync Work Statements</b>
                            <Switch
                                color='primary'
                                checked={this.getMetadataValue('workStatement')}
                                onChange={e => this.handleMetadataChange(e, 'workStatement')}
                                value="WorkStatement" />
                        </div>

                    </CardBox>
                </div>

                <div className='d-flex justify-content-end'>
                    <Button
                        onClick={() => this.onSendSettings()}
                        variant="contained"
                        disabled={this.state.onSyncingStatus}
                        style={{ width: "85px" }}
                        className={`jr-btn bg-deep-orange  text-white`}>
                        {this.state.onSyncingStatus ? <CircularProgress color="white" style={{ marginLeft: '10px' }} size={15} /> : "Apply"}
                    </Button>
                    <Button
                        onClick={() => this.props.onCloseDialog('dailyLogSettings')}
                        variant="contained"
                        disabled={this.state.onSyncingStatus}
                        style={{ width: "85px" }}
                        className={`jr-btn bg-gray  text-black`}>
                        Close
                    </Button>
                </div>
            </div>
        )
    }
}