import React from 'react';
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, TextField, Typography } from '@material-ui/core';
import { formatPhoneNumber } from '../../constants/helpers';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));


export default function IntegrationGeneralInfo(props) {

  const { open, integration, onShowIntegrationInfo } = props;

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const classes = useStyles();

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="lg" >
      <DialogTitle id="form-dialog-title">
        <img width='120px' height='auto' className="mb-1" src="https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/integrations-logos/procore-logo.png" alt="Integration Logo" />
      </DialogTitle>
      <DialogContent>
        <div>
          <Typography style={{ marginBottom: '10px' }} variant="h6">Integration Info</Typography>
          <div className={classes.container}>

            <div>
              <Typography variant="subtitle1">Token Expired: {get(integration, 'tokenExpired') ? "True" : "False"}</Typography>
              <Typography variant="subtitle1">Created At: {get(integration, 'info.created_at')}</Typography>
              <Typography variant="subtitle1">Total Projects: {get(integration, 'info.counterProjects.total')}</Typography>
              <Typography variant="subtitle1">Projects Imported: {get(integration, 'info.counterProjects.imported')}</Typography>

              {/* Add more user information fields as needed */}
            </div>

          </div>
        </div>
        <div>
          <Typography style={{ marginBottom: '10px' }} variant="h6">User Admin Info</Typography>
          <div className={classes.container}>

            <Avatar className={classes.avatar} src={get(userInfo, 'integrations.procore.profile')} alt="userInfo" />
            <div>
              <Typography variant="subtitle1">{get(userInfo, 'integrations.procore.fullName')}</Typography>
              <Typography variant="subtitle1">{get(userInfo, 'integrations.procore.email')}</Typography>
              <Typography variant="subtitle2">{formatPhoneNumber(get(userInfo, 'integrations.procore.phone'))}</Typography>
              {/* Add more user information fields as needed */}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onShowIntegrationInfo(null, false)} >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
