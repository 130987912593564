import React, { useState, useEffect, useContext, useRef } from 'react';
import { get } from 'lodash';
import {  notificationCenter } from '../../../../services/appService'
import { Button, makeStyles, CircularProgress, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

import { Widget } from '../../../../components';
import { SettingsContext } from '../../../../contexts/SettingsContext';
import SyncAltOutlined from "@material-ui/icons/SyncAltOutlined";
import { syncAllProcoreClassifications } from '../../../../services/integrationService';

const W3CWebSocket = require('websocket').w3cwebsocket;

const useStyles = makeStyles(theme => ({
  button: {
    padding: "3px 10px !important",
    marginRight: '10px !important',
    textTransform: 'capitalize',
    borderRadius: '4px !important'
  },
  textFieldUrl: {
    height: '20px',
    width: "300px",
    marginRight: "14px"
  }
}))

export default function CMICImportWorkers(props) {

  const settingsContext = useContext(SettingsContext);
  const classes = useStyles();

  const inputLinkRef = useRef(null);

  const [state, setState] = useState({
    user: JSON.parse(localStorage.getItem('userInfo')),
    import_classification: get(JSON.parse(localStorage.getItem('userInfo')), 'integrations.procore.import_classification' , false),
    totalClassification: 0,
    syncClassificationsResults: false,
    positions: [],
    confirmSync: false
  });

  
  const toggleSpinner = (spinnerFlag = true, text = 'Loading...') => {
    settingsContext.onChangeLoader(spinnerFlag, text);
  };


  const onError = e => {
    toggleSpinner(false);
    settingsContext.onRequestErrorMessage(e.response);
  };

  const syncClassifications = async () => {
    try {
      toggleSpinner(true , 'Syncing Classifications')
      setState(prevState => ({
        ...prevState,
        syncClassificationsResults: true,
        positions: [],
        confirmSync: false
      }))
      const res = await syncAllProcoreClassifications();
      
    } catch (error) {
      toggleSpinner(false)
      onError(error)
    }
  }


  useEffect(() => {
    settingsContext.onChangeMenu('procore', 3)
    openWebSocketConnection()
  }, [])

  const openWebSocketConnection = () => {

    let client = new W3CWebSocket(`${get(process.env, 'REACT_APP_WSS_DASHBOARD_CONNECTION')}?userId=${get(state.user, 'user.user_id')}&companyId=${get(state.user, 'user.company_id')}&token=${localStorage.getItem('userToken')}`);

    client.onopen = (e) => {
      console.log("OPEN ", e);
    };

    client.onclose = (e) => {
      console.log("ONCLOSE ", e);
      client = null;

      openWebSocketConnection();
    };

    client.onmessage = (e) => {
      console.log("On MESSAGE")
      const res = JSON.parse(get(e, "data"));
      console.log(res)
      toggleSpinner(false)
      console.log(res)

      switch (get(res, 'section.key')) {

        case "classificationData":
          toggleSpinner(false)
          setState(prevState => ({

            ...prevState,
            positions: [
              ...prevState.positions,
              get(res , 'section.data')
            ]
          }))
          break;
        case "synced":
          setState(prevState => ({
            ...prevState,
            syncClassificationsResults: false
          }))
          notificationCenter('success', 'Classifications Synced!')
          break;
        default:
          break;
      }

    };
  };

  const onConfirmSync = (status = true) => {
    setState(prevState => ({
      ...prevState,
      confirmSync: status
    }))
  }


  return (
    <>
      {state.syncClassificationsResults &&
        <>
          <Alert severity="warning">Stay on page until process is done!</Alert>

          <br />
        </>
      }

      <Widget title=' ' logoWidth='130' logo='https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/integrations-logos/procore-logo.png' styleName="jr-card-profile-sm widget-procore-projects">
        <div className='project-details d-flex align-items-center'>
          <div style={{width: '170px', marginRight: '10px'}}>Status: <span style={{fontWeight: 'bold' , color: 'green'}}>{state.import_classification ? 'Enabled': 'Disabled'}</span></div>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      
            <Button
              variant="contained"
              color="default"
              disabled={state.syncClassificationsResults}
              className={classes.button}
              onClick={() => state.import_classification ? syncClassifications() : onConfirmSync()}
              startIcon={state.syncClassificationsResults ? <CircularProgress size={15} color="primary" /> : <SyncAltOutlined />}
            >
              Sync Classifications
            </Button>
            
          </div>
        </div>
      </Widget>


      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">Number</TableCell>
              <TableCell align="left">Procore Id</TableCell>
              <TableCell align="left">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.positions.map((row , idx) => (
              <TableRow key={idx}>
                <TableCell component="th" scope="row">
                  {get(row, 'name')}
                </TableCell>
                <TableCell align="left">{get(row, 'description')}</TableCell>
                <TableCell align="left">{get(row, 'number')}</TableCell>
                <TableCell align="left">{get(row, 'integrations.procore')}</TableCell>
                <TableCell align="left">{get(row, 'status')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      <Dialog
        open={state.confirmSync}
        onClose={() => onConfirmSync(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"You are about to sync procore classifications with SmartBarrel's positions."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{display: 'flex' , flexDirection: 'column' , alignItems: 'center'}}>
            <p>Moving forward, you will have to import all positions from Procore.</p>
            <span style={{color: 'red' , fontWeight: 'bold'}}>THIS CANNOT BE UNDONE</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() =>  onConfirmSync(false)} color="primary">
            Disagree
          </Button>
          <Button onClick={() => syncClassifications()} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

    </>
  )
};