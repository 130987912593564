import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import { Send, FilterList } from '@material-ui/icons';

import {
  DateRangeFilterComponent,
  CompanyFilterComponent,
  CompanyCodesFilterComponent
} from './Filters/index';
import { Button } from '@material-ui/core';


export default function FiltersConfigComponent({ onGettingData, onPushingHours, hasData, filters, integrationKey }) {
  const [state, setState] = useState({
    filters,
    integrationKey,
    readyToSend: false
  });


  const readyToSync = filters => {
    return _.isUndefined(_.find(filters, item => _.get(item, 'isRequired') && _.isUndefined(_.get(item, 'data'))));
  }

  const onFilterChange = (key, value) => {

    const filterIndex = _.findIndex(filters, filter => _.get(filter, 'key') === key);

    _.set(filters, `${filterIndex}.data`, value);
    console.log(readyToSync(filters))
    setState(prevState => ({
      ...prevState,
      readyToSend: readyToSync(filters),
      filters
    }))
  }


  return (
    <div className='d-flex justify-content-between al'>
      <div className='filters d-flex justify-content-center align-items-center'>
        {filters.map(filter => (
          <div key={_.get(filter, 'key')} className='filter-item ml-4'>
            {_.get(filter, "key") === 'dateRange' && !_.has(filter, 'defaultValue') ?
              <DateRangeFilterComponent onFilterChange={onFilterChange} /> : null
            }
            {_.get(filter, "key") === 'companyCodes' && !_.has(filter, 'defaultValue') ?
              <CompanyCodesFilterComponent filter={filter} onFilterChange={onFilterChange} /> : null
            }
            {_.get(filter, "key") === 'company' && !_.has(filter, 'defaultValue') ?
              <CompanyFilterComponent /> : null
            }
          </div>
        ))}
      </div>
      <div >
        <Button
          variant="contained"
          disabled={!state.readyToSend}
          onClick={() => onGettingData({ filters: state.filters })}
          startIcon={<FilterList />}>
          Filters
          </Button>
        <Button
          variant="contained"
          className='ml-1'
          disabled={!hasData}
          onClick={() => onPushingHours({ filters: state.filters })}
          startIcon={<Send />}>
          Send
          </Button>
      </div>
    </div>
  )
};