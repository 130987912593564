import React, { useState, useContext, useEffect } from "react";
import { get, split, last } from 'lodash';
import {
    setupProcoreService,
    company_integrations_update
} from '../../../../services/integrationService';
import {
    ProcoreDialogConfirmation
} from './index';
import { SettingsContext } from "../../../../contexts/SettingsContext";

export default function ProcoreSetUp(props) {

    const settingsContext = useContext(SettingsContext);


    const [state, setState] = useState({
        procoreSyncData: [],
        dialogConfirmationStatus: false
    });

    const toggleSpinner = (spinnerFlag = true, text = 'Loading...') => {
        settingsContext.onChangeLoader(spinnerFlag, text);
    };

    const onError = e => {
        toggleSpinner(false);
        settingsContext.onRequestErrorMessage(e);
    };

    const onCompanyConfirmation = async (procore_company, wasConfirmed) => {

        try {

            toggleSpinner(true);

            const data = {
                key: 'companies',
                procId: get(procore_company, 'id'),
                wasConfirmed
            };

            await company_integrations_update(data);

            window.location.href = '/procore-projects';

        } catch (e) {
            onError(e.response);
            // setTimeout(() => window.location.href = '/home', 3000);
        }
    };

    const setupService = async () => {
        try {

            toggleSpinner(true);

            const code = last(split(get(props, 'location.search'), '='));

            const res = get(await setupProcoreService(code), 'data');

            if (get(res, 'updatingToken', false)) {
                console.log(res);
                window.location.href = '/procore-projects'
            } else {
                setState(prevState => ({
                    ...prevState,
                    procoreSyncData: res,
                    dialogConfirmationStatus: true
                }));
            }

            toggleSpinner(false)

        } catch (e) {
            toggleSpinner(false);
            console.log("ERROR", e.response)
            onError(e.response);

            setTimeout(() => window.location.href = '/home', 3000);
        }

    }

    useEffect(() => {
        setupService()
    }, []);

    return (
        state.dialogConfirmationStatus ?
            <ProcoreDialogConfirmation onCompanyConfirmation={onCompanyConfirmation}
                procore={state.procoreSyncData} /> : null
    )
};