import React, { useContext, useState } from 'react';
import { Avatar, Menu, MenuItem } from '@material-ui/core'
import { get } from 'lodash';

export default function UserInfo(props) {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    const [state, setState] = useState({
        anchorEl: null,
        open: false,
    });


    const handleClick = event => {
        setState({ open: true, anchorEl: event.currentTarget });
    };

    const onSignOut = () => {
        setState(prevState => ({
            ...prevState,
            open: false
        }), () => window.location = `${get(process.env, 'REACT_APP_BASE_DASHBOARD_URL')}/signoff`);
    };

    const handleRequestClose = () => {
        setState(prevState => ({
            ...prevState,
            open: false
        }));
    };

    return (
        <div className="user-profile d-flex flex-row align-items-center justify-content-center">
            <Avatar
                alt='...'
                src={get(userInfo, 'user.profile', null)}
                className="user-avatar "
            />
            <div className="user-detail">
                <h4 className="user-name" onClick={handleClick}>{get(userInfo, 'user.fullName', null)}
                    <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
                </h4>

            </div>
            <Menu className="user-info"
                id="simple-menu"
                anchorEl={state.anchorEl}
                open={state.open}
                onClose={handleRequestClose}
                PaperProps={{
                    style: {
                        minWidth: 120,
                        paddingTop: 0,
                        paddingBottom: 0
                    }
                }}
            >
                <MenuItem onClick={() => onSignOut()}>
                    <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
                    Logout
                </MenuItem>
            </Menu>
        </div>

    )
};