import React, { useEffect, useState } from "react";

import { Switch, Button, CircularProgress } from "@material-ui/core";
import { CardBox, Widget } from "../../../../components";
import { get } from 'lodash';
import '../../../../static/styles/components/_adpSettings.scss';
import { getUserInfo, notificationCenter } from "../../../../services/appService";
import { workersSyncServices, adpSettingsServices } from "../../../../services/adpServices";

const W3CWebSocket = require('websocket').w3cwebsocket;

function AdpSettings(props) {

    const [state, setState] = useState({
        user: getUserInfo(),
        syncingWorkers: false,
        workersSynced: false,
        synced: {
            total: 0,
            current: 0
        }
    });

    useEffect(() => {
        openWebSocketConnection();

        const settings = async () => {
            const res = await adpSettingsServices();
            console.log(res)
            props.globalState.onSpinner(false);
            setState(prevState => ({
                ...prevState,
                workersSynced: get(res, 'data.workersSynced')
            }))
        }

        settings();

    }, []);


    const onWorkerSync = () => {

        if (state.workersSynced) {
            props.history.push(`/adp-workers`)
        } else {
            props.globalState.onSpinner(true);
            workersSyncServices();
        }


    };

    const onPushingHours = () => {
        props.history.push(`/payroll-config/adp`)
    }

    const openWebSocketConnection = () => {
        let client = new W3CWebSocket(`${get(process.env, 'REACT_APP_WSS_CONNECTION')}?userId=${get(state.user, 'user_id')}&companyId=${get(state.user, 'company_id')}`);

        client.onopen = (e) => {
            console.log("OPEN ", e);
        };

        client.onclose = (e) => {
            console.log("ONCLOSE ", e);
            client = null;

            this.openWebSocketConnection();
        };

        client.onmessage = (e) => {

            const res = JSON.parse(get(e, "data"));

            console.log("RES SOCKETS ", res)

            switch (get(res, 'section.key')) {
                case "syncWorkersTotal":
                    props.globalState.onSpinner(false);
                    notificationCenter('warning', 'You will notified when the process finish');
                    setState(prevState => ({
                        ...prevState,
                        syncingWorkers: true,
                        synced: {
                            ...prevState.synced,
                            total: get(res, 'section.value')
                        }
                    }))
                    break;
                case "workerSynced":
                    setState(prevState => ({
                        ...prevState,
                        synced: {
                            ...prevState.synced,
                            current: prevState.synced.current + 1
                        }
                    }))
                    break;
                case "syncWorkersDone":
                    setState(prevState => ({
                        ...prevState,
                        syncingWorkers: false,
                        workersSynced: true
                    }))
                    break;
                default:
                    break;
            }

        };
    };


    return (
        <div>
            <Widget title=' ' logo={'https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/integrations-logos/adp-logo.png'} styleName="jr-card-profile-sm widget-procore-projects logo-adp-style">
            </Widget>
            <div className='adp-settings d-flex justify-content-center'>
                <CardBox className='adp-settings-cards' styles={{ with: '300px' }} title='Workers'
                    logoSrc='https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/integrations/ManpowerLogIcon.svg'>
                    {get(state, 'syncingWorkers') ?

                        <div className='syncing-progress-info'>
                            <CircularProgress className='mr-2' size={10} color="primary" />
                            {get(state, 'synced.current')} / <strong>{get(state, 'synced.total')}</strong>
                        </div> :

                        <Button
                            style={{ with: '100px' }}
                            variant="contained"
                            onClick={() => onWorkerSync()}
                            className="jr-btn bg-success text-white">{state.workersSynced ? 'Workers' : "Sync Workers"}</Button>
                    }

                </CardBox>
                <CardBox className='adp-settings-cards' styles={{ with: '300px' }} title='Pushing Hours'
                    logoSrc='https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/integrations/PushingHoursIcon.svg'>

                    <Button
                        disabled={!state.workersSynced}
                        style={{ with: '100px' }}
                        variant="contained"
                        onClick={() => onPushingHours()}
                        className={`jr-btn text-white ${state.workersSynced ? 'bg-success' : 'bg-gray'} `}>Pushing Hours</Button>
                </CardBox>
                {/* <CardBox title='Weather Log'
                logoSrc='https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/icons/integrations/WeatherLogIcon.svg'>
                <b>Hourly Weather Log</b>
                <Button
                    style={{ with: '100px' }}
                    variant="contained"
                    // onClick={() => onWorkerSync()}
                    className="jr-btn bg-success text-white">Pushing Hours</Button>
            </CardBox> */}
            </div>
        </div>

    );
}

export default AdpSettings;