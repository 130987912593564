import React, { useState, useEffect, Fragment } from 'react';
import { get, cloneDeep, isNull } from 'lodash';
import { FilterList } from '@material-ui/icons';

import moment from "moment";
import { DatePicker, Form } from 'antd';
import {
  Checkbox,
  FormControlLabel,
  Button
} from "@material-ui/core";

import { Widget } from '../../../../components';

const { RangePicker } = DatePicker;

export default function AdpReport(props) {

  const [dateValue, setDateValue] = useState(
    [moment(new Date()), moment(new Date())]
  );
  const [approved, setApproved] = useState(false)

  const [workers, setWorkers] = useState([])

  useEffect(() => {
    props.globalState.onSpinner(false);
  }, []);

  const onApprovedChange = value => {
    setApproved(value)
  }

  const onDatesChange = (range) => {
    setDateValue(range)
  }


  const onWorkers = () => {
    const filters = {
      _from: get(dateValue, '0').format('LL'),
      _to: get(dateValue, '1').format('LL'),
      approved
    }
  }


  return (
    <Fragment>

      <Widget title='Filters' styleName="jr-card-profile-sm d-flex ">

        <div className='adp-filters d-flex align-items-center  justify-content-between'>

          <div className='filter d-flex align-items-center'>
            <Form.Item label="Date Range">

              <RangePicker
                format="MM/DD/YYYY"
                value={dateValue}
                onChange={(event) => onDatesChange(event)}
              />
            </Form.Item>

            <FormControlLabel
              className='mb-4 ml-1'
              control={
                <Checkbox
                  checked={approved}
                  onChange={(e) => onApprovedChange(e.target.checked)}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Show approved shifts only"
            />
          </div>



          <Button

            variant="contained"
            onClick={() => onWorkers()}
            startIcon={<FilterList />}
            className="jr-btn bg-success text-white mb-4">Filter</Button>

        </div>




      </Widget>

      <div className="">

      </div>

    </Fragment>

  )
};