import React from 'react';
import { Button, Link, TableCell, TableRow } from "@material-ui/core";
import { get, isNull, isUndefined, find } from "lodash";
import { notValid } from "../../../../services/appService";
import { StatusField } from "../../../../components";
import { Badge } from "reactstrap";
import {
    ProcoreProjectSettings
} from './index'


const ProcoreProjectsBody = ({ projects, onProjectSummary, onProjectSettingSync, projectImport }) => {

    console.log("OPROJEC ", projects)

    return (
        projects.map(proj => (
            <TableRow key={proj.id}>
                <TableCell component="th" scope="row">
                    {get(proj, 'project_number', '-')}
                </TableCell>
                <TableCell>
                    <Link href="#" onClick={() => onProjectSummary(get(proj, 'id'), get(proj, 'sb_company_id'))}>
                        {!notValid(get(proj, 'name')) ? get(proj, 'name') : <StatusField />}
                    </Link>
                </TableCell>

                <TableCell>
                    {!notValid(get(proj, 'address')) ? get(proj, 'address') : <StatusField />}
                </TableCell>
                <TableCell>
                    {!notValid(get(proj, 'city')) ? get(proj, 'city') : <StatusField />}
                </TableCell>
                <TableCell>
                    {!notValid(get(proj, 'state_code')) ? get(proj, 'state_code') : <StatusField />}
                </TableCell>

                <TableCell>
                    {!notValid(get(proj, 'zip')) ? get(proj, 'zip') : <StatusField />}
                </TableCell>

                <TableCell align='center'>{isNull(get(proj, 'last_sync')) ?
                    <Badge color="danger"> Never</Badge> :
                    <Badge color="success">{get(proj, 'last_sync')}</Badge>}
                </TableCell>
                <ProcoreProjectSettings proj={proj} onProjectSettingSync={onProjectSettingSync} />
                <TableCell align='center'>
                    <div >
                        {
                            get(proj, 'isImported') ?
                                <Button
                                    variant="contained"
                                    disabled={true}
                                    style={{ width: "85px" }}
                                    className={`jr-btn ${!get(proj, 'isImported') ? 'bg-teal' : 'bg-gray'}  text-white`}>
                                    <i className="zmdi zmdi-download" />
                                    <span>Imported</span>
                                </Button> :
                                <Button onClick={() => projectImport(proj)}
                                    variant="contained"
                                    disabled={!get(proj, 'permissions.projectAccess', true)}
                                    style={{ width: "85px" }}
                                    className={`jr-btn ${!get(proj, 'isImported') && get(proj, 'permissions.projectAccess', true) ? 'bg-deep-orange' : 'bg-gray'}  text-white`}>
                                    <i className="zmdi zmdi-download" />
                                    <span>Import</span>
                                </Button>
                        }
                    </div>
                </TableCell>
            </TableRow>
        ))
    );
};

export default ProcoreProjectsBody;