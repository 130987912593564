import React, { Fragment } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, Button } from "@material-ui/core";
import { get, size } from "lodash";

const renderInactiveVendors = (props) => {

    return (

        <Fragment>
            <p className='mt-3 jr-font-weight-bold'>This vendors has been disabled or deleted from Procore. Do you want to keep it in SmartBarrel? </p>

            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Vendor Name</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {get(props, 'vendors.warningVendors', []).map(vendor => (
                        <TableRow key={vendor.id}>
                            <TableCell>
                                {get(vendor, 'name', '-')}
                                <i style={{
                                    display: "block",
                                    fontSize: "10px",
                                    color: 'red'
                                }}>{get(vendor, 'syncStatus.message')}</i>
                            </TableCell>
                            <TableCell align='center'>
                                <div className='d-flex'>
                                    <Button onClick={() => props.onKeepingVendor(vendor, true)} variant="contained" className={`jr-btn bg-green text-white`}>
                                        <i className="zmdi zmdi-thumb-up" />
                                        <span>Yes</span>
                                    </Button>

                                    <Button onClick={() => props.onKeepingVendor(vendor, false)} variant="contained" className={`jr-btn bg-danger text-white`}>
                                        <i className="zmdi zmdi-thumb-down" />
                                        <span>No</span>
                                    </Button>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Fragment>

    );

};


const ProcoreProjectVendors = (props) => {

    return (
        <div className='procore-projects'>
            <Table aria-label="simple table ">
                <TableHead>
                    <TableRow>
                        <TableCell>Vendor Name</TableCell>
                        <TableCell align='center'>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {get(props, 'vendors.successVendors', []).map(vendor => (
                        <TableRow key={vendor.id}>
                            <TableCell>
                                {get(vendor, 'name', '-')}
                                <i style={{
                                    display: "block",
                                    fontSize: "10px",
                                    color: 'red'
                                }}>{get(vendor, 'syncStatus.message')}</i>
                            </TableCell>
                            <TableCell align='center'>
                                <span
                                    className={`d-flex justify-content-center  align-items-center text-white  badge badge-${get(vendor, 'syncStatus.icon')}`}>
                                    {get(vendor, 'syncStatus.text') === 'Pending' &&
                                        <CircularProgress variant="indeterminate" color='inherit' size={10} disableShrink />}
                                    <b className='pl-2'>{get(vendor, 'syncStatus.text')}</b>
                                </span>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {size(get(props, 'vendors.warningVendors', [])) > 0 && renderInactiveVendors(props)}

        </div>
    );
};


export default ProcoreProjectVendors;