import React, { useState, useEffect, useContext, useRef } from 'react';
import { get, isEmpty } from 'lodash';
import { syncAllWorkers, getRevokeCMICWorkerURL } from '../../../../services/cmicService';
import { getRevokeWorkersURL, getUserInfo, notificationCenter } from '../../../../services/appService'
import { Button, Dialog, DialogContent, DialogContentText, DialogActions, makeStyles, CircularProgress } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Alert from '@material-ui/lab/Alert';

import { Widget } from '../../../../components';
import { SettingsContext } from '../../../../contexts/SettingsContext';
import SyncAltOutlined from "@material-ui/icons/SyncAltOutlined";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import GetAppIcon from '@material-ui/icons/GetApp';

const W3CWebSocket = require('websocket').w3cwebsocket;

const useStyles = makeStyles(theme => ({
  button: {
    padding: "3px 10px !important",
    marginRight: '10px !important',
    textTransform: 'capitalize',
    borderRadius: '4px !important'
  },
  textFieldUrl: {
    height: '20px',
    width: "300px",
    marginRight: "14px"
  }
}))

export default function CMICImportWorkers(props) {

  const settingsContext = useContext(SettingsContext);
  const classes = useStyles();

  const inputLinkRef = useRef(null);

  const [state, setState] = useState({
    user: JSON.parse(localStorage.getItem('userInfo')),
    progress: 0,
    totalWorkers: 0,
    reason: {
      status: false,
      value: ''
    },
    syncWorkersResults: false,
    revokeWorkers: {
      status: false,
      url: '',
      syncing: false
    }
  });

  const [count, setCount] = useState(0);
  const [processed, setProcess] = useState(0);
  const [unProcessed, setUnProcess] = useState(0);

  const toggleSpinner = (spinnerFlag = true, text = 'Loading...') => {
    settingsContext.onChangeLoader(spinnerFlag, text);
  };


  useEffect(() => {
    if (state.revokeWorkers.status) {
      gettingRevokeWorkersUrl()
    }
  }, [state.revokeWorkers.status])

  const gettingRevokeWorkersUrl = async () => {

    try {

      setState(prevState => ({
        ...prevState,
        revokeWorkers: {
          ...prevState.revokeWorkers,
          syncing: true
        }
      }))

      const res = await getRevokeWorkersURL('cmic');


      setState(prevState => ({
        ...prevState,
        revokeWorkers: {
          ...prevState.revokeWorkers,
          syncing: false,
          url: get(res, 'data')
        }
      }))

    } catch (error) {

    }

  }


  const onError = e => {
    toggleSpinner(false);
    settingsContext.onRequestErrorMessage(e.response);
  };

  const syncWorkers = async () => {
    try {
      toggleSpinner(true, 'Syncing workers...');
      setState(prevState => ({
        ...prevState,
        totalWorkers: 0
      }))
      setUnProcess(0);
      setCount(0);
      setProcess(0);
      const res = await syncAllWorkers();
    } catch (error) {
      onError(error)
    }
  }


  const onGetRevokeWorkersDialog = () => {

    setState(prevState => ({
      ...prevState,
      revokeWorkers: {
        ...prevState.revokeWorkers,
        status: true
      }
    }))

  }

  useEffect(() => {
    settingsContext.onChangeMenu('cmic', 2)
    openWebSocketConnection()
  }, [])

  const openWebSocketConnection = () => {

    let client = new W3CWebSocket(`${get(process.env, 'REACT_APP_WSS_DASHBOARD_CONNECTION')}?userId=${get(state.user, 'user_id')}&companyId=${get(state.user, 'company_id')}&token=${localStorage.getItem('userToken')}`);

    client.onopen = (e) => {
      console.log("OPEN ", e);
    };

    client.onclose = (e) => {
      console.log("ONCLOSE ", e);
      client = null;

      openWebSocketConnection();
    };

    client.onmessage = (e) => {
      console.log("On MESSAGE")
      const res = JSON.parse(get(e, "data"));
      console.log(res)
      switch (get(res, 'event')) {
        case 'importEmployeeResult':

          if (count === 0) {
            toggleSpinner(false);
            setState(prevState => ({
              ...prevState,
              syncWorkersResults: true,

            }))
          }

          setCount(count => count + 1);

          get(res, 'data.processed') ? setProcess(processed => processed + 1) : setUnProcess(unProcessed => unProcessed + 1)

          if (get(res, 'data.last', false)) {
            console.log("the last ", get(res, 'data'))
            setState(prevState => ({
              ...prevState,
              syncWorkersResults: false
            }))
          }

          break;
        case 'totalWorkersToSync':
          console.log("ASHASDKJHASKJDHGAJKHSG")
          setState(prevState => ({
            ...prevState,
            syncWorkersResults: true,
            // totalWorkers: get(res, 'data')
          }))
          break;
        default:
          break;
      }

    };
  };

  const onCopyLink = () => {
    inputLinkRef.current.select();
    notificationCenter('success', 'Link Copied!')
  }

  const onOpenLink = () => {
    window.open(state.revokeWorkers.url, '_blank');
  };

  const onCloseSyncDialog = () => {
    setState(prevState => ({
      ...prevState,
      revokeWorkers: {
        status: false,
        url: '',
        syncing: false
      }
    }))
  }

  return (
    <>
      {state.syncWorkersResults &&
        <>
          <Alert severity="warning">Stay on page until process is done!</Alert>

          <br />
        </>
      }

      <Widget title=' ' logoWidth='130' logo='https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/integrations-logos/cmicLogo.png' styleName="jr-card-profile-sm widget-procore-projects">
        <div className='project-details d-flex align-items-center'>

          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>

            <div className="d-flex align-items-center mr-2">
              {/* <span style={{ width: '100%', fontSize: '15px' }}><b>Process:</b> {count}/{state.totalWorkers}</span> */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CheckCircleIcon style={{ marginRight: '3px', marginLeft: '10px', fontSize: '19px', color: '#02b500' }} /> {processed}
                <WarningIcon style={{ marginRight: '3px', marginLeft: '10px', fontSize: '19px', color: 'rgb(234 140 0)' }} /> {unProcessed}
              </div>
            </div>

            <Button
              variant="contained"
              color="default"
              disabled={state.syncWorkersResults}
              className={classes.button}
              onClick={() => syncWorkers()}
              startIcon={state.syncWorkersResults ? <CircularProgress size={15} color="primary" /> : <SyncAltOutlined />}
            >
              Sync Workers
            </Button>
            <Button
              variant="contained"
              color="default"
              onClick={() => onGetRevokeWorkersDialog()}
              disabled={state.syncWorkersResults}
              className={classes.button}
              startIcon={<GetAppIcon />}
            >
              Download Error Log
            </Button>
          </div>
        </div>
      </Widget>

      {state.revokeWorkers.status &&
        <Dialog
          open={state.revokeWorkers.status}
          onClose={() => onCloseSyncDialog()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Download workers list
            </DialogContentText>
            <div className="input-share-link d-flex justify-content-between align-items-center">
              <input style={{ width: '300px', height: '30px', marginRight: "10px" }} ref={inputLinkRef} disabled readOnly value={state.revokeWorkers.url} />
              <CopyToClipboard text={state.revokeWorkers.url}
                onCopy={() => onCopyLink()}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={state.revokeWorkers.syncing}
                  className={classes.button}
                  startIcon={<FileCopyIcon />}
                >
                  {state.revokeWorkers.syncing ? <CircularProgress size={15} color="primary" /> : "Copy"}
                </Button>
              </CopyToClipboard>

              <Button
                variant="contained"
                color="primary"
                disabled={state.revokeWorkers.syncing}
                className={classes.button}
                onClick={() => onOpenLink()}
                startIcon={<OpenInBrowserIcon />}
              >
                {state.revokeWorkers.syncing ? <CircularProgress size={15} color="primary" /> : "Open"}
              </Button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button disabled={state.revokeWorkers.syncing} onClick={() => onCloseSyncDialog()} color="primary">
              Done
            </Button>
          </DialogActions>
        </Dialog>
      }

    </>
  )
};