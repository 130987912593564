import React, { useContext, useEffect, useState } from 'react';
import { Router } from "react-router-dom";

import history from "../../services/history";
import Routes from "../../routes";
import { get } from "lodash";
import { Spinner } from "../../components";
import { SettingsContext } from '../../contexts/SettingsContext';

const W3CWebSocket = require('websocket').w3cwebsocket;

export default function APP(props) {

    const settingsContext = useContext(SettingsContext);

    return (
        <Router history={history}>
            {get(settingsContext, 'loader.status') && <Spinner text={get(settingsContext, 'loader.msg')} />}
            <Routes />
        </Router>
    )
};
