import React from "react";
import PropTypes from "prop-types";
import {Route, Redirect} from "react-router-dom";
import LayoutSB from "../containers/Layout/LayoutSB";

export default function RouteWrapper(
    {
        component: Component,
        ...rest
    }) {

    const signed = true;

    if (!signed) {
        return <Redirect to="http://staging.smartbarrel.io/login"/>;
    }

    return (
        <Route
            {...rest}
            render={props => (
                <div className="main-route">
                    <LayoutSB props={props}>
                        <Component {...props} {...rest} />
                    </LayoutSB>
                </div>
            )}
        />
    );
}

RouteWrapper.propTypes = {
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    props: PropTypes.any
};