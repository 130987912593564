import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";


const ProcoreTableComponent = (props) => {

    const { headers, children } = props;

    return (
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    {headers.map((header, idx) => (
                        <TableCell align={header.align} key={idx}>{header.title}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {children}
            </TableBody>
        </Table>
    );

};

ProcoreTableComponent.propTypes = {
    children: PropTypes.node.isRequired,
    headers: PropTypes.array.isRequired
};


export default ProcoreTableComponent;