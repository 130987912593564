import axios from 'axios';
import { get } from 'lodash';

const baseUrl = get(process.env, 'REACT_APP_BASE_URL', 'https://integrations.connectedbarrelapi.com');

const setHeaders = () => {
  return {
    'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
    'Accept': 'application/json',
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': "*"
  };
};


export const getPayrollConfig = key => {

  return new Promise((resolve, reject) => {

    axios.get(`${baseUrl}/payroll-config/${key}`, { headers: setHeaders() }).then(res => {
      resolve(get(res, 'data'));
    }).catch(err => {

      reject(err);
    });

  });
};

export const pushingHours = (data, key) => {

  console.log(data, key)

  return new Promise((resolve, reject) => {

    axios.post(`${baseUrl}/push-hours/${key}`, { data }, { headers: setHeaders() }).then(res => {
      resolve(get(res, 'data'));
    }).catch(err => {
      reject(err);
    });

  });
};

export const getPayrollData = (data, key) => {

  return new Promise((resolve, reject) => {

    axios.post(`${baseUrl}/payroll-data/${key}`, { data }, { headers: setHeaders() }).then(res => {
      resolve(get(res, 'data'));
    }).catch(err => {
      reject(err);
    });

  });
};