import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@material-ui/core";

import {
    ArrowPagination
} from '../../../../components/index'


const AdpWorkersTableComponent = (props) => {

    const { headers, children } = props;

    return (
        <Fragment >
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {headers.map((header, idx) => (
                            <TableCell align={header.align} key={idx}>{header.title}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {children}
                </TableBody>
            </Table>
        </Fragment>
    );
};

AdpWorkersTableComponent.propTypes = {
    children: PropTypes.node.isRequired,
    headers: PropTypes.array.isRequired
};

export default AdpWorkersTableComponent;