import React, { useState, createContext } from 'react'
import { get, isNull, isUndefined } from 'lodash';

export const AuthContext = createContext();

export default function AuthContextProvider(props) {

  const [state, setState] = useState({
    userInfo: null,
    isLogged: !isNull(localStorage.getItem('userToken')),
    userToken: null
  });

  const onSetToken = data => {

    const token = get(data, 'match.params.sessionId', null);

    if (isNull(state.userToken) && !isNull(token)) {
      data.history.push('/home');
      localStorage.setItem('userToken', token)
      setState(prevState => ({
        ...prevState,
        isLogged: true,
        userToken: localStorage.getItem('userToken')
      }))
    }

  }

  const onUserInfo = data => {


    if (isNull(state.userInfo) && !isNull(data)) {

      localStorage.setItem('userInfo', JSON.stringify(data))
      setState(prevState => ({
        ...prevState,
        isLogged: true,
        userInfo: {
          user_id: get(data, 'user_id'),
          fullName: get(data, 'fullName'),
          phone: get(data, 'phone'),
          profile: get(data, 'profile', "https://s3.us-west-2.amazonaws.com/static-files.connectedbarrel.com/images/logo/worker.jpg"),
          isAdmin: get(data, 'isAdmin', false)
        }
      }))
    }


  }

  const onLogout = () => {
    localStorage.clear()
    setState(prevState => ({
      ...prevState,
      userInfo: {},
      isLogged: false,
      userToken: null
    }))
  }

  return (
    <AuthContext.Provider value={{
      ...state,
      onSetToken,
      onLogout,
      onUserInfo
    }}>
      {props.children}
    </AuthContext.Provider>
  )
};