import React, { Component } from 'react'

import {
    DailyLogSetting,
    ResourceManagementSetting
} from "./index";

import {
    get,
    find,
    cloneDeep
} from 'lodash';

import {
    DialogConfirmation
} from "../../../../components/index";

import { Button, TableCell } from "@material-ui/core";

export default class ProcoreProjectSettings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dailyLogSettings: {
                status: false
            },
            resourceManagement: {
                status: false
            }

        }
    }

    onDialogClose = (type) => {

        this.setState(prevState => ({
            ...prevState,
            [type]: {
                status: false
            }
        }))
    };

    settingsPermission = (permissionType) => {

        const proj = this.props.proj;

        if (!get(proj, 'isImported') || !get(proj, 'permissions.projectAccess', true)) return true;

        const dailyPermission = find(get(proj, 'permissions', []), item => get(item, 'engine_name') === permissionType);
        return !get(dailyPermission, 'is_active')
    };

    onProjectSettings = (settingType) => {
        this.setState(prevState => ({
            ...prevState,
            [settingType]: {
                status: true
            }
        }))
    };

    onDailyLogSettingsData = (procProj, data) => {

        const daily_settings = {
            procId: get(procProj, 'id'),
            daily_sync: {
                AM: {
                    status: get(data, 'dailyLog.AM.status', false),
                    time: {
                        hour: get(data, 'dailyLog.AM.value.hour', null),
                        minute: 0
                    }
                },
                PM: {
                    status: get(data, 'dailyLog.PM.status', false),
                    time: {
                        hour: get(data, 'dailyLog.PM.value.hour', null),
                        minute: 0
                    }
                },
                syncSubContractorWorkers: get(data, 'dailyLog.syncSubContractorWorkers', false),
                staticShiftsHours: get(data, 'dailyLog.staticShiftsHours', []),
                filters: get(data, 'dailyLog.filters', []),
                metadata: get(data, 'dailyLog.metadata', []),
                type: get(data, 'dailyLog.type', null)

            },
            weather_sync: {
                status: get(data, 'weatherLog', false)
            }
        };
        this.props.onProjectSettingSync(daily_settings)
    };

    onResourceManagementSettingsData = (procProj, data) => {
        const resourceManagement_settings = {
            procId: get(procProj, 'id'),
            timeCards_sync: {
                syncSubContractorWorkers: get(data, 'timeCards.syncSubContractorWorkers', false),
                time_entry: get(data, 'timeCards.time_entry', 'total_hours'),
                dailyTimeSync: get(data, 'timeCards.dailyTimeSync', false),
                filters: get(data, 'timeCards.filters', [])
            },
            worker_sync: {
                status: get(data, 'workerSync', false)
            },
            costCode_sync: {
                status: get(data, 'costCodeSync', false)
            }

        };
        this.props.onProjectSettingSync(resourceManagement_settings)
    };

    render() {
        return (
            <React.Fragment>
                <DialogConfirmation open={this.state.dailyLogSettings.status}
                    onHandleClose={() => null}
                    disableBackdropClick={true}
                    title=''
                    agreeText='Done'
                    maxWidth='md'
                    showActions={false}
                    doneAction={false}
                    dialogContent={
                        <DailyLogSetting procProj={this.props.proj} onProjectSettingSync={this.onDailyLogSettingsData} onCloseDialog={this.onDialogClose} />
                    }
                />

                <DialogConfirmation open={this.state.resourceManagement.status}
                    onHandleClose={() => null}
                    disableBackdropClick={true}
                    title='Workforce Management'
                    agreeText='Apply'
                    disagreeText="Close"
                    maxWidth='lg'
                    showActions={false}
                    doneAction={false}
                    dialogContent={
                        <ResourceManagementSetting procProj={this.props.proj} onProjectSettingSync={this.onResourceManagementSettingsData} onCloseDialog={this.onDialogClose} />
                    }
                />


                <TableCell align='center'>
                    <Button onClick={() => this.onProjectSettings('dailyLogSettings')} disabled={this.settingsPermission('daily_log')}
                        variant="contained"
                        className={`jr-btn ${this.settingsPermission('daily_log') ? 'bg-gray' : 'bg-teal'} text-white`}>
                        <span>Settings</span>
                    </Button>
                </TableCell>
                <TableCell align='center'>
                    <Button onClick={() => this.onProjectSettings('resourceManagement')} disabled={this.settingsPermission('timesheets')}
                        variant="contained"
                        className={`jr-btn ${this.settingsPermission('timesheets') ? 'bg-gray' : 'bg-teal'} text-white`}>
                        <span>Settings</span>
                    </Button>
                </TableCell>
            </React.Fragment>
        )
    }
}
