import React from 'react';
import PropTypes from 'prop-types';

import {
    TablePagination
} from '@material-ui/core';

const PaginationTable = (props) => {

    return (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={props.count}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            backIconButtonProps={{
                'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
                'aria-label': 'next page',
            }}
            onChangePage={props.onChangePage}
            onChangeRowsPerPage={props.onChangeRowsPage}
        />

    );
};

PaginationTable.propTypes = {
    count: PropTypes.number,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
    onChangePage: PropTypes.func.isRequired,
    onChangeRowsPage: PropTypes.func.isRequired,
};

PaginationTable.defaultProps = {
    count: 100,
    rowsPerPage: 25,
    page: 0
};

export default PaginationTable;