import React from 'react';
import { TableCell, TableRow, Button, CircularProgress } from "@material-ui/core";
import { get } from "lodash";
import { notValid } from "../../../../services/appService";
import { StatusField } from "../../../../components";

const syncStatusInfo = worker => {

    let value = '';
    switch (get(worker, 'syncStatus')) {
        case 'readyToSync':
            value = 'Ready To Sync'
            break;
        case 'missingInfo':
            value = 'Missing Info.'
            break;
        case 'synced':
            value = 'Synced'
            break;
        default:
            break;
    }

    return value;
}

const AdpWorkersTableBodyComponent = ({ workers, onReadyWorkers, readyWorkerSynced }) => {
    return (

        workers.map((worker, idx) => (
            <TableRow key={idx}>
                <TableCell component="th" scope="row">
                    {get(worker, 'first_name', '-')}
                </TableCell>
                <TableCell>
                    {get(worker, 'last_name', '-')}
                </TableCell>
                <TableCell>
                    {get(worker, 'email', '-')}
                </TableCell>
                <TableCell>
                    {!notValid(get(worker, 'phoneNumber')) ? get(worker, 'phoneNumber') : <StatusField />}
                </TableCell>
                <TableCell>
                    {syncStatusInfo(worker)}
                </TableCell>
                <TableCell align='center'>
                    <div>
                        <Button
                            disabled={get(worker, 'syncStatus') !== 'readyToSync' || readyWorkerSynced}
                            onClick={() => onReadyWorkers(get(worker, '_id'))}
                            variant="contained"
                            style={{ width: "85px" }}
                            className={`jr-btn bg-${get(worker, 'syncStatus') === 'readyToSync' && !readyWorkerSynced ? 'teal' : 'gray'} text-white`}>
                            <span>{readyWorkerSynced ? <CircularProgress className='mr-2' size={10} color="primary" /> : null} Sync</span>
                        </Button>


                    </div>
                </TableCell>
            </TableRow>
        ))


    );
};

export default AdpWorkersTableBodyComponent;